import {
    Box,
    Flex,
    FormControl,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Switch,
    Text,
    Tooltip,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import AppTableWithPagination from 'components/AppTableWithPagination';
import AdSetUpdateForm from 'components/genericForms/AdsetUpdateForm';
import { useEffect, useMemo, useState } from 'react';
import { FiEdit2 } from "react-icons/fi";
import { useSearchParams } from 'react-router-dom';
import { useGetAdsetsListQuery, useUpdateAdsetStatusMutation } from 'store/campaigns/adsetFormApi';
import adsetMapping from '../../utils/adsetMapping.json'
import moment from 'moment';


function AdSetsTable({ adsAccountId }) {
    const toast = useToast();
    const [searchParams] = useSearchParams();
    const adsetId = searchParams.get('adsetId');
    const isSearchDisabled = Boolean(adsetId);
    const adsetName = searchParams.get('name');

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [hasError, setHasError] = useState(false);
    const [selectedAdset, setselectedAdset] = useState(null);
    const [switchStates, setSwitchStates] = useState({});

    const { data: adsetsList, isFetching, isLoading, error, refetch } = useGetAdsetsListQuery({
        currentPage,
        searchValue,
        adsAccountId
    }, { skip: !adsAccountId });

    const [updateAdsetStatus, { isLoading: isAdsetStatusUpdating }] = useUpdateAdsetStatusMutation({});

    //console.log({adsetsList});

    useEffect(() => {
        if (adsetId) {
            setSearchValue('');
        }
    }, [adsetId]);
    
    const filteredAdsets = useMemo(() => {
       
        if (adsetId) {
            return adsetsList?.data?.filter(adset => adset.adset_id === adsetId) || [];
        }
        return adsetsList?.data || []; 
    }, [adsetsList, adsetId]);

    const handleSaveSuccess = () => {
        refetch();
    };
    
    useEffect(() => {
        
        if (adsAccountId) {
            refetch();
        }
    }, [adsAccountId])

    useEffect(() => {
        if (adsetsList?.data) {
            const initialSwitchStates = {};
            adsetsList.data.forEach(adset => {
                initialSwitchStates[adset.adset_id] = adset.status;
            });
            setSwitchStates(initialSwitchStates);
        }
    }, [adsetsList]);

    useEffect(() => {
        if (error) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [error]);

    const handleSwitchChange = (adsetId, currentState) => {
        const newStatus = currentState === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';

        // update the switch state
        setSwitchStates(prev => ({ ...prev, [adsetId]: newStatus }));
        updateAdsetStatus({
            adsetId,
            payload: { status: newStatus }
        }).unwrap()
            .then(() => {
                refetch();
                toast({
                    position: "top-right",
                    title: `Adset ${newStatus === 'ACTIVE' ? 'activated' : 'paused'}`,
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                toast({
                    position: "top-right",
                    title: `Error updating Adset status`,
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
                console.error('Error updating Adset status:', error);

                // Revert switch state if API call fails
                setSwitchStates(prev => ({ ...prev, [adsetId]: currentState }));
            });
    };

    const columns = useMemo(
        () => [
            {
                Header: "Off/On",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const isActive = switchStates[original.adset_id] ?? (original.status === 'ACTIVE');
                    const tooltipText = isActive ? 'Ad set is ON' : 'Ad set is OFF';

                    return (
                        <Flex gap={'10px'} align={'center'}>              
                            <Tooltip label={tooltipText} fontSize='xs' >
                                <FormControl width={'fit-content'}>
                                    <Switch
                                        colorScheme='orange'
                                        size={'sm'}
                                        isChecked={original.status === 'ACTIVE'}
                                        onChange={() => handleSwitchChange(original.adset_id, original.status)}
                                    />
                                </FormControl>
                            </Tooltip>
                        </Flex>
                    )
                },
            },
            {
                Header: "Ad set ID",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Flex gap={'10px'} alignItems={'center'}> 
                        <Tooltip label='Edit' fontSize='xs' >
                            <Flex>
                                <Icon
                                    _hover={{ color: "gray.500" }}
                                    as={FiEdit2}
                                    cursor={"pointer"}
                                    onClick={() => {
                                        setselectedAdset(original);
                                        onOpen();
                                    }}
                                />
                            </Flex>
                        </Tooltip>
                        <Text fontSize={'12px'}>{original.adset_id}</Text>
                    </Flex>
                )
            },            
            {
                Header: "Name",
                accessor: "name",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text>{original.name}</Text>
                ),
            },
            {
                Header: "Destination Type",
                accessor: "WEBSITE",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const label = adsetMapping?.destinationTypes[original.destination_type] || 'N/A';
                    return <Text>{label}</Text>;
                }
            },
            {
                Header: "Optimization Goal",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const destinationType = original.destination_type;
                    const optimizationGoal = adsetMapping?.optimizationGoals[destinationType]?.find(goal => goal.value === original.optimization_goal);
                    return <Text>{optimizationGoal ? optimizationGoal.label : 'N/A'}</Text>;
                },
            },
            {
                Header: "Insight",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Box>
                        {original.insight && Object.keys(original.insight).length > 0 ? (
                            <>
                                <Text fontSize={'12px'}>CTR: {original.insight?.ctr}</Text>
                                <Text fontSize={'12px'}>Spend: {original.insight?.spend}</Text>
                                <Text fontSize={'12px'}>Date Start: {moment(original.insight?.date_start).format("DD-MM-YYYY")}</Text>
                                <Text fontSize={'12px'}>Date Stop: {moment(original.insight?.date_stop).format("DD-MM-YYYY")}</Text>
                            </>
                        ) : (
                            null
                        )}
                    </Box>
                )   
            },
            {
                Header: "Status",
                accessor: "status",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text>{original.status}</Text>
                ),
            },
            {
                Header: "Created Date",
                accessor: "createdDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                  <Text>{moment(original.createdDate).format("DD-MM-YYYY hh:mm A")}</Text>
        
                ),
            },
            {
                Header: "Updated Date",
                accessor: "updatedDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                  <Text>{moment(original.updatedDate).format("DD-MM-YYYY hh:mm A")}</Text>
        
                ),
            },
        ],
        []
    );

    return (
        <>
            {/* EDIT ADSET MODAL */}
            <Modal
                size={"2xl"}
                isCentered={true}
                isOpen={isOpen}
                scrollBehavior={"inside"}
                onClose={() => {onClose(); setselectedAdset(null)}}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Ad set</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <AdSetUpdateForm adsetData={selectedAdset} onClose={onClose} onSaveSuccess={handleSaveSuccess} />
                    </ModalBody>
                </ModalContent>
            </Modal>

            {isLoading ? (
                <Spinner mt="20px" />
                ) : (
                <AppTableWithPagination
                    columns={columns}
                    data={filteredAdsets || []}
                    searchEnabled={!isSearchDisabled}
                    isLoading={false}
                    isFetching={isFetching}
                    initialState={{ pageIndex: currentPage - 1 }}
                    paginationMeta={adsetsList?.meta || {}}
                    onPageChange={setCurrentPage}
                    onSearchInputChange={setSearchValue}
                    searchTerm={searchValue}
                />
                )}
        </>
    );

}

export default AdSetsTable;
