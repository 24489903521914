import React from 'react';
import { Box, Input, Select, Flex, Text, Tooltip, Button, Spinner } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

const AdspressoCampaignForm = ({
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    dataCampaign,
    isLoadingCampaign,
    errorCampaign,
    isSubmitting,
    handleSubmit
}) => {
    const requiredFields = ['name','objective'];

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <Box flex="1" overflowY="auto" display="flex" flexDirection="column">
                {isLoadingCampaign ? (
                    <Spinner mt="20px" />
                ) : errorCampaign ? (
                    <Text color="red.500">Failed to load campaign form data</Text>
                ) : (
                    <>
                        {dataCampaign?.data ? (
                            <form onSubmit={handleSubmit}>
                                <Box borderRadius="md" width="100%" maxWidth="600px" >
                                    <Flex direction="column" gap={'20px'} bg="gray.50" px={'10px'} pt={'10px'} borderRadius="md">
                                        {Object.keys(dataCampaign.data).map((key) => {
                                            const field = dataCampaign.data[key];
                                            const isRequired = requiredFields.includes(key);

                                            return (
                                                <Box key={key} >
                                                    <Flex align="center">
                                                        <Text mb={2}>
                                                            {field.label}
                                                        </Text>
                                                        {isRequired && (
                                                            <Text display="inline-flex" fontSize="18px" color="red.400" mb={2}>*&nbsp;</Text>
                                                        )}
                                                        {key === 'objective' && (
                                                            <>
                                                                <Tooltip
                                                                    borderRadius={5}
                                                                    label={
                                                                        <Box fontSize="sm" p={'5px'}>
                                                                            <Text textAlign={'justify'}>Choose what you want people to do when they see your ads. Your campaign objective should align with your overall business goals.</Text>
                                                                        </Box>
                                                                    }
                                                                    fontSize="md"
                                                                >
                                                                    <InfoOutlineIcon pointerEvents="all" mb={2} />
                                                                </Tooltip>
                                                            </>
                                                        )}
                                                    </Flex>
                                                    {field.type === 'text' ? (
                                                        <Input
                                                            placeholder={`Enter ${field.label}`}
                                                            bg="#fff"
                                                            name={key}
                                                            value={values[key] || ''}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            size={'sm'}
                                                        />
                                                    ) : field.type === 'select' ? (
                                                        <Select
                                                            bg="#fff"
                                                            name={key}
                                                            value={values[key] || ''}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            size={'sm'}
                                                        >
                                                            {field.options.map((option) => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </Select>
                                                    ) : null}
                                                    {touched[key] && errors[key] && (
                                                        <Text fontSize={'12px'} color="red.500">{errors[key]}</Text>
                                                    )}
                                                </Box>
                                            );
                                        })}
                                    </Flex>

                                    {/* Submit Button in a separate Flex container */}
                                    <Flex  >
                                        <Button
                                            colorScheme="orange"
                                            isLoading={isSubmitting}
                                            type="submit"
                                            size={'sm'}
                                            mt={'20px'}
                                            mb={'10px'}
                                        >
                                            Continue
                                        </Button>
                                    </Flex>
                                </Box>
                            </form>
                        ) : (
                            <Text>No campaign data available</Text>
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};

export default AdspressoCampaignForm;
