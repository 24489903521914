import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
  ModalBody, ModalFooter, Input, Button, Flex, Text, Select, Box, RadioGroup, Stack, Radio, Checkbox, Tooltip, IconButton
} from '@chakra-ui/react';
import { InfoOutlineIcon, AddIcon, CloseIcon } from "@chakra-ui/icons";
import { useState } from 'react';
import ReactSelect from 'react-select';


const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const generateTimeOptions = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const value = `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
      times.push({ value, label: value });
    }
  }
  return times;
};

const timeOptions = generateTimeOptions();


function CreateRuleForm({ isOpen, onClose }) {
  const [customSchedules, setCustomSchedules] = useState(daysOfWeek.map(() => ({
    days: [],
    timeSlots: [{ startTime: '', endTime: '' }]
  })));
  

  const validationSchema = yup.object({
    ruleName: yup.string().required('Rule name is required'),
    applyTo: yup.string().required('Please select where to apply the rule'),
    action: yup.string().required('Please select an action'),
    conditionValue: yup.number().required('Condition value is required'),
    timeRange: yup.string().required('Please select a time range'),
    schedule: yup.string().required('Please select a schedule'),
    customSchedules: yup.array().of(
      yup.object().shape({
        days: yup.array().min(1, 'At least one day must be selected'),
        timeSlots: yup.array().of(
          yup.object().shape({
            startTime: yup.string().required('Start time is required'),
            endTime: yup.string().required('End time is required'),
          })
        ).min(1, 'At least one time slot must be added'),
      })
    )
  });

  const createRuleFormik = useFormik({
    initialValues: {
      ruleName: '',
      applyTo: 'all_active_ad_sets',
      action: 'turn_off_ad_sets',
      conditionField: 'cost_per_result',
      conditionOperator: 'is_greater_than',
      conditionValue: '',
      timeRange: 'maximum',
      schedule: 'continuously',
      notification: true,
      subscriber: 'Unknown',
      customSchedules: [],
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      onClose();
    },
  });

  const handleDayChange = (index, day) => {
    const newSchedules = [...customSchedules];
    if (newSchedules[index].days.includes(day)) {
      newSchedules[index].days = newSchedules[index].days.filter(d => d !== day);
    } else {
      newSchedules[index].days.push(day);
    }
    setCustomSchedules(newSchedules);
    createRuleFormik.setFieldValue('customSchedules', newSchedules);
  };

  const handleAddTimeSlot = (scheduleIndex) => {
    const newSchedules = [...customSchedules];
    newSchedules[scheduleIndex].timeSlots.push({ startTime: '', endTime: '' });
    setCustomSchedules(newSchedules);
    createRuleFormik.setFieldValue('customSchedules', newSchedules);
  };

  const handleTimeSlotChange = (scheduleIndex, slotIndex, field, value) => {
    const newSchedules = [...customSchedules];
    newSchedules[scheduleIndex].timeSlots[slotIndex][field] = value;
    setCustomSchedules(newSchedules);
    createRuleFormik.setFieldValue('customSchedules', newSchedules);
  };

  const handleRemoveTimeSlot = (scheduleIndex, slotIndex) => {
    const newSchedules = [...customSchedules];
    newSchedules[scheduleIndex].timeSlots.splice(slotIndex, 1);
    setCustomSchedules(newSchedules);
    createRuleFormik.setFieldValue('customSchedules', newSchedules);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" isCentered>
      <ModalOverlay />
      <ModalContent maxWidth="700px" overflow='hidden'>
        <form onSubmit={createRuleFormik.handleSubmit}>
          <ModalHeader boxShadow="md" p={'12px'}>
          <Text fontWeight="bold" fontSize={'20px'}>Create rule:custom rule</Text>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody as={Box} maxHeight="70vh" overflowY="auto">
            <Text mb={4} fontSize={'14px'} >
              Automatically update campaigns, ad sets, or ads in bulk by creating automated rules.
            </Text>

            <Flex direction="column" gap={4}>
              {/* Rule name */}
              <Flex direction="column" gap={1}>
                <Text fontWeight="bold">Rule name</Text>
                <Input
                  name="ruleName"
                  placeholder="Rule name"
                  value={createRuleFormik.values.ruleName}
                  onChange={createRuleFormik.handleChange}
                  onBlur={createRuleFormik.handleBlur}
                />
                {createRuleFormik.touched.ruleName && createRuleFormik.errors.ruleName && (
                  <Text color="red.500" fontSize="sm">{createRuleFormik.errors.ruleName}</Text>
                )}
              </Flex>

              {/* Apply rule to */}
              <Flex direction="column" gap={1}>
                <Text fontWeight="bold">Apply rule to</Text>
                <Select
                  name="applyTo"
                  value={createRuleFormik.values.applyTo}
                  onChange={createRuleFormik.handleChange}
                  onBlur={createRuleFormik.handleBlur}
                >
                  <option value="all_active_campaigns">All active campaigns</option>
                  <option value="all_active_ad_sets">All active ad sets</option>
                  <option value="all_active_ads">All active ads</option>
                </Select>
                {createRuleFormik.touched.applyTo && createRuleFormik.errors.applyTo && (
                  <Text color="red.500" fontSize="sm">{createRuleFormik.errors.applyTo}</Text>
                )}
              </Flex>

              {/* Action */}
              <Flex direction="column" gap={1}>
                <Text fontWeight="bold">Action</Text>
                <Select
                  name="action"
                  value={createRuleFormik.values.action}
                  onChange={createRuleFormik.handleChange}
                  onBlur={createRuleFormik.handleBlur}
                >
                  <option value="turn_off_ad_sets">Turn off ad sets</option>
                  <option value="turn_on_ad_sets">Turn on ad sets</option>
                  <option value="send_notification_only">Send notification only</option>
                </Select>
                {createRuleFormik.touched.action && createRuleFormik.errors.action && (
                  <Text color="red.500" fontSize="sm">{createRuleFormik.errors.action}</Text>
                )}
              </Flex>

              {/* Conditions */}
              <Flex direction="column">
                <Flex alignItems={'center'} gap={1}>
                  <Text fontWeight="bold">Conditions</Text>
                  <Tooltip
                    borderRadius={5}
                    label={
                      <Box fontSize="sm" p="5px">
                        <Text>These will be used to evaluate whether the rule's action should be taken on each of your selected ad sets.</Text>
                      </Box>
                    }
                    fontSize="md"
                  >
                    <InfoOutlineIcon pointerEvents="all" />
                  </Tooltip>
                </Flex>
                <Text fontSize={'12px'}>ALL of the following match</Text>
                <Flex gap={2} mt={"5px"}>
                  <Select
                    name="conditionField"
                    value={createRuleFormik.values.conditionField}
                    onChange={createRuleFormik.handleChange}
                  >
                    <option value="cost_per_result">Cost per result</option>
                    {/* Add more options as needed */}
                  </Select>
                  <Select
                    name="conditionOperator"
                    value={createRuleFormik.values.conditionOperator}
                    onChange={createRuleFormik.handleChange}
                  >
                    <option value="is_greater_than">is greater than</option>
                    <option value="is_smaller_than">is smaller than</option>
                    <option value="is_between">is between</option>
                    <option value="is_not_between">is not between</option>
                  </Select>
                  <Input
                    name="conditionValue"
                    placeholder="₹"
                    value={createRuleFormik.values.conditionValue}
                    onChange={createRuleFormik.handleChange}
                    onBlur={createRuleFormik.handleBlur}
                  />
                </Flex>
                {createRuleFormik.touched.conditionValue && createRuleFormik.errors.conditionValue && (
                  <Text color="red.500" fontSize="sm">{createRuleFormik.errors.conditionValue}</Text>
                )}
              </Flex>

              {/* Time range */}
              <Flex direction="column" gap={1} mt={'5px'}>
                <Flex alignItems={'center'} gap={1}>
                  <Text fontWeight="bold">Time range</Text>
                  <Tooltip
                    borderRadius={5}
                    label={
                      <Box fontSize="sm" p="5px">
                        <Text>This is the number of days of data you'd like to apply your rule to.</Text>
                      </Box>
                    }
                    fontSize="md"
                  >
                    <InfoOutlineIcon pointerEvents="all" />
                  </Tooltip>
                </Flex>
             
                <Select
                  name="timeRange"
                  value={createRuleFormik.values.timeRange}
                  onChange={createRuleFormik.handleChange}
                  onBlur={createRuleFormik.handleBlur}
                >
                  <option value="maximum">Maximum</option>
                  <option value="last_7_days">Last 7 days</option>
                  <option value="last_14_days">Last 14 days</option>
                  <option value="last_30_days">Last 30 days</option>
                  
                </Select>
                {createRuleFormik.touched.timeRange && createRuleFormik.errors.timeRange && (
                  <Text color="red.500" fontSize="sm">{createRuleFormik.errors.timeRange}</Text>
                )}
              </Flex>

              {/* Schedule */}
              <Flex direction="column" gap={1}>
                <Flex alignItems={'center'} gap={1}>
                  <Text fontWeight="bold">Schedule</Text>
                  <Tooltip
                    borderRadius={5}
                    label={
                      <Box fontSize="sm" p="5px">
                        <Text>This will determine how frequently to check the rule. Your rule will be checked in your ad account's time zone.</Text>
                      </Box>
                    }
                    fontSize="md"
                  >
                    <InfoOutlineIcon pointerEvents="all" />
                  </Tooltip>
                </Flex>
                <RadioGroup
                  name="schedule"
                  value={createRuleFormik.values.schedule}
                  onChange={(value) => {
                    createRuleFormik.setFieldValue('schedule', value);
                    if (value === 'custom') {
                      createRuleFormik.setFieldValue('customSchedules', daysOfWeek.map(() => ({
                        days: [],
                        timeSlots: [{ startTime: '', endTime: '' }]
                      })));
                      setCustomSchedules(daysOfWeek.map(() => ({
                        days: [],
                        timeSlots: [{ startTime: '', endTime: '' }]
                      })));
                    }
                  }}
                >
                  <Stack spacing={5} direction='column'>
                    <Flex direction="column">
                      <Flex align="center">
                        <Radio colorScheme='orange' value='continuously' />
                        <Text fontWeight="bold" ml={2}>Continuously</Text>
                      </Flex>
                      <Text fontSize={'sm'} ml={6}>Rule runs as often as possible (usually every 30-60 minutes).</Text>
                    </Flex>

                    <Flex direction="column">
                      <Flex align="center">
                        <Radio colorScheme='orange' value='daily' />
                        <Text fontWeight="bold" ml={2}>Daily </Text>
                      </Flex>
                      <Text fontSize={'sm'} ml={6}>at 12.00 am Kolkata Time</Text>
                    </Flex>

                    <Flex direction="column">
                      <Flex align="center">
                        <Radio colorScheme='orange' value='custom' />
                        <Text fontWeight="bold" ml={2}>Custom </Text>
                      </Flex>
                      <Text fontSize={'sm'} ml={6}>Adjust the rule schedule to run on specific days and at specific times of day.</Text>
                      {createRuleFormik.values.schedule === 'custom' && (
                        <Flex direction="column" mt={'12px'} ml={'12px'}>
                        {customSchedules.map((schedule, index) => (
                        <Flex key={index} direction="column" gap={2}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Flex flexBasis="20%">
                              <Checkbox
                                isChecked={schedule.days.includes(daysOfWeek[index])}
                                onChange={() => handleDayChange(index, daysOfWeek[index])}
                                colorScheme={'orange'}
                              >
                                {daysOfWeek[index]}
                              </Checkbox>
                            </Flex>
                            <Flex flexBasis="35%" style={{ width: '200px' }} justify="center">
                              <ReactSelect
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    width: '200px', 
                                  }),
                                  option: (base, state) => ({
                                    ...base,
                                    backgroundColor: state.isSelected ? 'lightgray' : state.isFocused ? 'lightgray' : 'white',
                                    color: 'black',
                                  }),
                                }}
                                options={timeOptions}
                                isDisabled={!schedule.days.includes(daysOfWeek[index])}
                                value={schedule.timeSlots[0]?.startTime ? timeOptions.find(option => option.value === schedule.timeSlots[0]?.startTime) : null}
                                onChange={(selectedOption) => handleTimeSlotChange(index, 0, 'startTime', selectedOption.value)}
                                placeholder="00:00"
                              />
                            </Flex>
                            <Text fontSize="sm">to</Text>
                            <Flex flexBasis="35%" justify="center">
                              <ReactSelect
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    width: '200px', 
                                  }),
                                  option: (base, state) => ({
                                    ...base,
                                    backgroundColor: state.isSelected ? 'lightgray' : state.isFocused ? 'lightgray' : 'white',
                                    color: 'black',
                                  }),
                                }}
                                options={timeOptions}
                                isDisabled={!schedule.days.includes(daysOfWeek[index])}
                                value={schedule.timeSlots[0]?.endTime ? timeOptions.find(option => option.value === schedule.timeSlots[0]?.endTime) : null}
                                onChange={(selectedOption) => handleTimeSlotChange(index, 0, 'endTime', selectedOption.value)}
                                placeholder="00:00"            
                              />
                            </Flex>
                            <Flex flexBasis="10%" justify="center">
                              <IconButton
                                icon={<AddIcon />}
                                onClick={() => handleAddTimeSlot(index)}
                                aria-label="Add time slot"
                                colorScheme="orange"
                              />
                            </Flex>
                          </Stack>

                          {/* Render additional time slots */}
                          <Flex direction="column" mt={2}>
                            {schedule.timeSlots.slice(1).map((slot, slotIndex) => (
                              <Flex key={slotIndex} gap={2} alignItems="center" mb={2}>
                                <Flex flexBasis="20%">
                                  {/* Keeping the day checkbox empty to align with the first row */}
                                </Flex>
                                <Flex flexBasis="35%" justify="center">
                                  <ReactSelect
                                     styles={{
                                      control: (base) => ({
                                        ...base,
                                        width: '200px', 
                                      }),
                                      option: (base, state) => ({
                                        ...base,
                                        backgroundColor: state.isSelected ? 'lightgray' : state.isFocused ? 'lightgray' : 'white',
                                        color: 'black',
                                      }),
                                    }}
                                    options={timeOptions}
                                    isDisabled={!schedule.days.includes(daysOfWeek[index])}
                                    value={slot.startTime ? timeOptions.find(option => option.value === slot.startTime) : null}
                                    onChange={(selectedOption) => handleTimeSlotChange(index, slotIndex + 1, 'startTime', selectedOption.value)}
                                    placeholder="00:00"
                                  />
                                </Flex>
                                <Text fontSize="sm">to</Text>
                                <Flex flexBasis="35%" justify="center">
                                  <ReactSelect
                                    styles={{
                                      control: (base) => ({
                                        ...base,
                                        width: '200px', 
                                      }),
                                      option: (base, state) => ({
                                        ...base,
                                        backgroundColor: state.isSelected ? 'lightgray' : state.isFocused ? 'lightgray' : 'white',
                                        color: 'black',
                                      }),
                                    }}
                                    options={timeOptions}
                                    isDisabled={!schedule.days.includes(daysOfWeek[index])}
                                    value={slot.endTime ? timeOptions.find(option => option.value === slot.endTime) : null}
                                    onChange={(selectedOption) => handleTimeSlotChange(index, slotIndex + 1, 'endTime', selectedOption.value)}
                                    placeholder="00:00"
                                  />
                                </Flex>
                                <Flex flexBasis="10%" justify="center">
                                  <IconButton
                                    icon={<CloseIcon />}
                                    onClick={() => handleRemoveTimeSlot(index, slotIndex + 1)}
                                    aria-label="Remove time slot"
                                    colorScheme="red"
                                  />
                                </Flex>
                              </Flex>
                            ))}
                          </Flex>
                        </Flex>
                        ))}
                        </Flex>
                      )}
                    </Flex>
                  </Stack>
                </RadioGroup>
                {createRuleFormik.touched.schedule && createRuleFormik.errors.schedule && (
                  <Text color="red.500" fontSize="sm">{createRuleFormik.errors.schedule}</Text>
                )}
              </Flex>

              {/* Notification */}
              <Flex direction="column" gap={2}>
                <Flex alignItems={'center'} gap={1}>
                  <Text fontWeight="bold">Notification</Text>
                  <Tooltip
                    borderRadius={5}
                    label={
                      <Box fontSize="sm" p="5px">
                        <Text>This is how you'll be notified that rule conditions have been met.</Text>
                      </Box>
                    }
                    fontSize="md"
                  >
                    <InfoOutlineIcon pointerEvents="all" />
                  </Tooltip>
                </Flex>
                <Stack spacing={2}>
                <Flex gap={2}>
                  <Checkbox
                    colorScheme={"orange"}
                    name="notification"
                    isChecked={createRuleFormik.values.notification}
                    onChange={createRuleFormik.handleChange}
                    isDisabled
                  />
                    <Flex direction="column" mt={'12px'}>
                      <Text fontSize="14px" fontWeight="bold">On Facebook</Text>
                      <Text fontSize="12px">You'll receive a notification when conditions for this rule have been met.</Text>
                    </Flex>
                  </Flex>
                </Stack>
              </Flex>

              {/* Subscriber */}
              <Flex direction="column" gap={1}>
                <Flex alignItems={'center'} gap={1}>
                  <Text fontWeight="bold">Subscriber</Text>
                  <Tooltip
                    borderRadius={5}
                    label={
                      <Box fontSize="sm" p="5px">
                        <Text>Only people with access to this ad account can receive rule results.</Text>
                      </Box>
                    }
                    fontSize="md"
                  >
                    <InfoOutlineIcon pointerEvents="all" />
                  </Tooltip>
                </Flex>
                <Text>{createRuleFormik.values.subscriber}</Text>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter css={{boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.2)'}} p={'12px'}>
            <Flex gap={2} align="center" justify="flex-start" width="100%">
              <Button variant="ghost" size={'sm'} onClick={onClose}>Cancel</Button>
              <Button type="submit" size={'sm'} colorScheme="orange">Create</Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default CreateRuleForm;
