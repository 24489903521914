import React, { useState, useEffect, useRef, memo } from 'react';
import { Box, Flex, Text, Checkbox, useColorModeValue, Grid, Spinner, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Heading } from '@chakra-ui/react';
import { useGetAdsetPlacementsQuery } from 'store/campaigns/adsetFormApi';

const placementMapping = {
    "feed": "Facebook Feed",
    "instream_video": "Audience Network instream video",
    "right_hand_column": "Facebook right column",
    "marketplace": "Facebook Marketplace",
    "search": "Messenger inbox",
    "facebook_reels": "Facebook reels",
    "story": "Facebook stories",
    "biz_disco_feed": "Facebook Business Explore",
    "facebook_reels_overlay": "Facebook reels overlay",
    "video_feeds": "Facebook video feeds",
    "stream": "Instagram feed",
    "explore": "Instagram Explore",
    "reels": "Instagram reels",
    "explore_home": "Instagram Explore home",
    "profile_feed": "Instagram profile feed",
    "ig_search": "Instagram search",
    "messenger_home": "Messenger inbox",
    "classic": "Audience Network Classic",
    "rewarded_video": "Audience Network rewarded video"
};

const PlacementsSelector = memo(function PlacementsSelector({
    buying_type,
    account_id,
    optimization_goal,
    objective,
    billing_event,
    destination_type,
    onPlacementsChange,
    currentAdsetData,
}) {

    const backgroundColor = useColorModeValue('gray.50', 'gray.800');


    const [selectedDevicePlatforms, setSelectedDevicePlatforms] = useState({
        mobile: true,
        desktop: true,
    });

    const [selectedPlatforms, setSelectedPlatforms] = useState({
        facebook: true,
        instagram: true,
        messenger: true,
        audience_network: true,
    });

    const [selectedPositions, setSelectedPositions] = useState({
        facebook: {},
        instagram: {},
        messenger: {},
        audience_network: {},
    });

    const [platformAvailability, setPlatformAvailability] = useState({
        facebook: true,
        instagram: true,
        messenger: true,
        audience_network: true,
    });

    //keep track of previous targeting values
    const previousTargetingRef = useRef({});

    const effectiveDevicePlatforms = Object.keys(selectedDevicePlatforms).filter(
        device => selectedDevicePlatforms[device]
    );

    const { data: response, error, isLoading } = useGetAdsetPlacementsQuery({
        optimization_goal,
        objective,
        buying_type,
        billing_event,
        account_id,
        destination_type,
        effective_device_platforms: optimization_goal === 'QUALITY_CALL' ? '' : effectiveDevicePlatforms.join(','),
    });

    // Prefill states from currentAdsetData
    useEffect(() => {
        if (currentAdsetData) {
            const {
                device_platforms,
                publisher_platforms,
                facebook_positions,
                instagram_positions,
                messenger_positions,
                audience_network_positions,
            } = currentAdsetData;

            // Set selected device platforms
            if (currentAdsetData?.device_platforms) {
                const updatedDevicePlatforms = {
                    mobile: device_platforms.includes('mobile'),
                    desktop: device_platforms.includes('desktop'),
                };
                setSelectedDevicePlatforms(updatedDevicePlatforms);
            }

            // Set selected platforms
            if (currentAdsetData?.publisher_platforms) {
                const updatedPlatforms = {
                    facebook: publisher_platforms.includes('facebook'),
                    instagram: publisher_platforms.includes('instagram'),
                    messenger: publisher_platforms.includes('messenger'),
                    audience_network: publisher_platforms.includes('audience_network'),
                };
                setSelectedPlatforms(updatedPlatforms);
            }

            // Initialize updatedPositions object
            const updatedPositions = {};

            // Set selected positions only if available
            if (Array.isArray(facebook_positions)) {
                updatedPositions.facebook = facebook_positions.reduce((acc, pos) => {
                    acc[pos] = true;
                    return acc;
                }, {});
            }
            if (Array.isArray(instagram_positions)) {
                updatedPositions.instagram = instagram_positions.reduce((acc, pos) => {
                    acc[pos] = true;
                    return acc;
                }, {});
            }
            if (Array.isArray(messenger_positions)) {
                updatedPositions.messenger = messenger_positions.reduce((acc, pos) => {
                    acc[pos] = true;
                    return acc;
                }, {});
            }
            if (Array.isArray(audience_network_positions)) {
                updatedPositions.audience_network = audience_network_positions.reduce((acc, pos) => {
                    acc[pos] = true;
                    return acc;
                }, {});
            }
            setSelectedPositions(updatedPositions);
        }
    }, [currentAdsetData]);



    useEffect(() => {
        if (response?.data) {
            const placements = response.data;
            const platformNames = ['facebook', 'instagram', 'messenger', 'audience_network'];

            const initialSelectedPositions = platformNames.reduce((acc, platform) => {
                const positions = placements[`effective_${platform}_positions`] || [];
                const updatedPositionsForPlatform = {};

                if (currentAdsetData) {
                    // If currentAdsetData exists, filter based on available positions
                    const currentPositions = currentAdsetData[`${platform}_positions`] || [];
                    currentPositions.forEach(pos => {
                        if (positions.includes(pos)) {
                            updatedPositionsForPlatform[pos] = true;
                        }
                    });
                } else {
                    // select all available positions
                    positions.forEach(pos => {
                        updatedPositionsForPlatform[pos] = true;
                    });
                }

                acc[platform] = updatedPositionsForPlatform;
                return acc;
            }, {});

            // Update platform availability based on data
            const availability = platformNames.reduce((acc, platform) => {
                acc[platform] = (placements[`effective_${platform}_positions`] || []).length > 0;
                return acc;
            }, {});

            setSelectedPositions(initialSelectedPositions);
            setPlatformAvailability(availability);
        }
    }, [response, currentAdsetData]);


    useEffect(() => {
        const targeting = {
            publisher_platforms: Object.keys(selectedPlatforms).filter(platform => selectedPlatforms[platform]),
            ...Object.keys(selectedPositions).reduce((acc, platform) => {
                acc[`${platform}_positions`] = Object.keys(selectedPositions[platform]).filter(position => selectedPositions[platform][position]);
                return acc;
            }, {}),
        };

        if (optimization_goal !== 'QUALITY_CALL') {
            targeting.device_platforms = effectiveDevicePlatforms;
        }

        // Only call onPlacementsChange if any change
        const newTargeting = JSON.stringify(targeting);
        if (newTargeting !== JSON.stringify(previousTargetingRef.current)) {
            previousTargetingRef.current = targeting; // Saving the latest targeting
            onPlacementsChange(targeting);
            //console.log(targeting);

        }

    }, [selectedDevicePlatforms, selectedPlatforms, selectedPositions, optimization_goal, effectiveDevicePlatforms]);


    if (isLoading) {
        return <Spinner size={'sm'} />;
    }

    if (error) {
        return <Text color="red.500">Error fetching placements: {error.data.message}</Text>;
    }

    const placements = response?.data || {};

    const getPlacementName = (key) => placementMapping[key] || key;

    // Handle device checkbox changes
    const handleDeviceChange = (device) => {
        setSelectedDevicePlatforms(prev => {
            const newState = { ...prev, [device]: !prev[device] };
            if (!newState[device]) {
                const otherDevice = device === 'mobile' ? 'desktop' : 'mobile';
                newState[otherDevice] = true;
            }
            return newState;
        });
    };

    const handlePlatformChange = (platform) => {
        setSelectedPlatforms(prev => {
            const newState = { ...prev, [platform]: !prev[platform] };
            const positions = placements[`effective_${platform}_positions`] || [];
            setSelectedPositions(prevPositions => ({
                ...prevPositions,
                [platform]: positions.reduce((acc, pos) => ({
                    ...acc,
                    [pos]: newState[platform],
                }), {}),
            }));
            return newState;
        });
    };

    const handlePositionChange = (platform, position) => {
        setSelectedPositions(prev => ({
            ...prev,
            [platform]: {
                ...prev[platform],
                [position]: !prev[platform][position],
            },
        }));
    };

    const formatPlatformName = (platform) => {
        return platform.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };


    const convertToSentenceCase = (text) => {
        return text
            .split('_') // Split the text at underscores
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' '); // Join the words with spaces
    };

    return (
        <Box p={4} borderRadius="md" bg={backgroundColor} mb={4}>
            {/* Placements Header */}
            <Flex justifyContent="space-between" alignItems="center" mb={4}>
                <Text fontWeight="bold" fontSize="lg">Placements</Text>
            </Flex>

            {/* Devices section */}
            {optimization_goal !== 'QUALITY_CALL' && (
                <Box mb={'20px'}>
                    <Text fontWeight={'bold'} mb={2}>Devices</Text>
                    <Flex gap={2} alignItems={'center'} mb={2}>
                        <Accordion allowToggle>
                            <AccordionItem>
                                <Heading>
                                    <AccordionButton>
                                        <Box flex="1" textAlign="left">
                                            All devices (recommended)
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </Heading>
                                <AccordionPanel pb={4}>
                                    <Flex direction="column" gap={2}>
                                        {['mobile', 'desktop'].map(device => (
                                            <Checkbox
                                                key={device}
                                                colorScheme="orange"
                                                isChecked={!!selectedDevicePlatforms[device]}
                                                isDisabled={device === 'mobile' ? !selectedDevicePlatforms['desktop'] : !selectedDevicePlatforms['mobile']}
                                                onChange={() => handleDeviceChange(device)}
                                            >
                                                {device.charAt(0).toUpperCase() + device.slice(1)}
                                            </Checkbox>
                                        ))}
                                    </Flex>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Flex>
                </Box>
            )}

            {/* Platforms */}
            <Box mb={'20px'}>
                <Text fontWeight={'bold'} mb={2}>Platforms</Text>
                <Grid templateColumns="repeat(2, 200px)" gap={2} mb={4}>
                    {['facebook', 'instagram', 'messenger', 'audience_network'].map(platform => (
                        <Checkbox
                            key={platform}
                            colorScheme="orange"
                            isChecked={!!selectedPlatforms[platform] && platformAvailability[platform]}
                            isDisabled={!platformAvailability[platform]}
                            onChange={() => handlePlatformChange(platform)}
                        >
                            {convertToSentenceCase(platform)}
                        </Checkbox>
                    ))}
                </Grid>
            </Box>

            {/* Position Sections with Accordion */}
            <Text fontWeight={'bold'} mb={2}>Placements</Text>
            <Accordion allowToggle>
                {['facebook', 'instagram', 'messenger', 'audience_network'].map(platform => {
                    // Get the positions for the platform
                    const platformPositions = selectedPositions[platform] || {};
                    const availablePositions = placements[`effective_${platform}_positions`] || [];

                    return (
                        <AccordionItem key={platform}>
                            <AccordionButton>
                                <Box flex="1" textAlign="left">
                                    <Text fontSize={'14px'} fontWeight="bold">{formatPlatformName(platform)}</Text>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                {availablePositions.length > 0 ? (
                                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                                        {availablePositions.map(position => (
                                            <Box
                                                key={position}
                                                // bg="white"
                                                p={2}
                                                borderRadius="md"
                                                borderWidth="1px"
                                                borderColor="gray.200"
                                            >
                                                <Flex justifyContent="space-between" alignItems="center">
                                                    <Text fontSize={'14px'}>{getPlacementName(position)}</Text>
                                                    <Checkbox
                                                        colorScheme="orange"
                                                        isChecked={selectedPlatforms[platform] && platformPositions[position]} // Check if the platform is selected and the specific position is selected
                                                        onChange={() => handlePositionChange(platform, position)} // Handle checkbox change
                                                    />
                                                </Flex>
                                            </Box>
                                        ))}
                                    </Grid>
                                ) : (
                                    <Text color="gray.500">No placements available for {formatPlatformName(platform)}.</Text>
                                )}
                            </AccordionPanel>
                        </AccordionItem>
                    );
                })}
            </Accordion>

        </Box>
    );
});

export default PlacementsSelector;
