import {
    Box,
    Button,
    Flex,
    Input,
    Select,
    Spinner,
    Text,
    useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useGetAdCreativeQuery, useUpdateAdMutation } from 'store/campaigns/adsApi';
import FaceBookPageSelector from 'components/adspresso/FaceBookPageSelector';
import DriveModal from 'components/drive/DriveModal';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const SingleAdUpdateForm = ({ adData, adsAccountData, onClose }) => {
    const toast = useToast();
    const { creative } = adData || {};
    const [isDriveModalOpen, setIsDriveModalOpen] = useState(false);
    
    const { data: adCreative } = useGetAdCreativeQuery(
        { creativeId: creative.id },
        { skip: !creative.id }
    );

    //console.log({adData})
    
    const [updateAd] = useUpdateAdMutation();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Ad Name is required'),
        creative: Yup.object().shape({
            name: Yup.string().required('Headline is required'),
            object_story_spec: Yup.object().shape({
                link_data: Yup.object().shape({
                    message: Yup.string().required('Primary Text is required'),
                    link: Yup.string().url('Invalid URL').required('Link URL is required'),
                    image_hash: Yup.string().required('Image Hash is required')
                }),
                page_id: Yup.string().required('Facebook Page ID is required')
            })
        }),
        image_url: Yup.string().url('Invalid URL'),
    });

    const formik = useFormik({
        initialValues: {
            name: adData?.name || '',
            hash: adData?.hash || '',
            creative: {
                name: adCreative?.name || '',
                object_story_spec: {
                    link_data: {
                        call_to_action: {
                            type: adCreative?.object_story_spec?.link_data?.call_to_action?.type || "",
                        },
                        message: adCreative?.object_story_spec?.link_data?.message || '',
                        link: adCreative?.object_story_spec?.link_data?.link || '',
                        image_hash: adCreative?.object_story_spec?.link_data?.image_hash || '',
                        video_id:  adCreative?.object_story_spec?.link_data?.video_id || ''
                    },
                    page_id: adCreative?.object_story_spec?.page_id || '',
                },
            },
            image_url: adCreative?.image_url || '',
            picture: adCreative?.picture || '',
        },
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {

            const payload = {
                name: values.name,
                hash:values.hash,
                creative: {
                    name: values?.creative?.name,
                    image_url: values?.image_url,
                    object_story_spec: {
                        link_data: {
                            call_to_action: {
                                type: values?.creative?.object_story_spec?.link_data?.call_to_action?.type || "",
                            },
                            message: values?.creative?.object_story_spec.link_data?.message,
                            link: values?.creative?.object_story_spec.link_data?.link,
                            image_hash: values?.creative?.object_story_spec?.link_data?.image_hash,
                        },
                        page_id: values?.creative.object_story_spec?.page_id,
                    },
                    degrees_of_freedom_spec: {
                        creative_features_spec: {
                            standard_enhancements: {
                                enroll_status : "OPT_OUT"
                            }
                        }
                    },
                    adsAccount: {
                        id: adsAccountData._id,
                        adsAccountId: adsAccountData.adsAccountId,
                        adsAccountName: adsAccountData.adsAccountName,
                    },
                },
            };

            updateAd({ creativeId: adData.id, payload })
                .unwrap()
                .then(response => {
                    toast({
                        position: "top-right",
                        title: "Ad updated",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .catch(error => {
                    toast({
                        position: "top-right",
                        title: "Error updating ad",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                    onClose();
                });
        },
    });

    const handleOpenDriveModal = () => {
        setIsDriveModalOpen(true);
    };

    const handleMediaSelect = (file) => {
        if (file && file.length > 0) {
            const selectedFile = file[0]; 

            formik.setFieldValue('hash', selectedFile?.hash);
            formik.setFieldValue('image_url', selectedFile?.url);
            formik.setFieldValue('creative.object_story_spec.link_data.image_hash', selectedFile?.creativeId);
        }   
        setIsDriveModalOpen(false);
    }; 

    if (!adCreative) {
        return <Spinner mt={'20px'} />;
    }

    const isImage = Boolean(formik.values.creative.object_story_spec.link_data.image_hash);
    const buttonLabel = isImage ? "Change Image" : "Change Video";

    return (
        <form onSubmit={formik.handleSubmit}>
            <Flex direction={'column'} borderRadius="md" bg="gray.50" p={4} gap={4}>
                <Flex direction="column" gap={1}>
                    <Text fontWeight="bold">Ad Name</Text>
                    <Input
                        name="name"
                        placeholder="Enter your ad name here..."
                        bg="#fff"
                        size='sm'
                        borderRadius="md"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.name && formik.errors.name && (
                        <Text fontSize={"12px"} color="red.500">{formik.errors.name}</Text>
                    )}
                </Flex>

                <Flex direction="column" gap={1}>
                    <Text fontWeight="bold">Facebook Page ID</Text>
                    <FaceBookPageSelector
                        adsAccount={adsAccountData}
                        selectedPageId={formik.values.creative.object_story_spec.page_id}
                        onSelectedPage={(pageId) => {
                            formik.setFieldValue('creative.object_story_spec.page_id', pageId);
                        }}
                    />
                    {formik.errors.creative?.object_story_spec?.page_id && (
                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.page_id}</Text>
                    )}
                </Flex>

                <Flex direction="column" gap={1}>
                    <Text fontWeight="bold">Headline</Text>
                    <Input
                        name="creative.name"
                        placeholder="Enter a headline"
                        bg="#fff"
                        size='sm'
                        borderRadius="md"
                        value={formik.values.creative.name}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.creative?.name && formik.errors.creative?.name && (
                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.name}</Text>
                    )}
                </Flex>

                <Flex direction="column" gap={1}>
                    <Text fontWeight="bold">Primary Text</Text>
                    <Input
                        name="creative.object_story_spec.link_data.message"
                        placeholder="Message"
                        bg="#fff"
                        size='sm'
                        borderRadius="md"
                        value={formik.values.creative.object_story_spec.link_data.message}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.creative?.object_story_spec?.link_data?.message && formik.errors.creative?.object_story_spec?.link_data?.message && (
                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.message}</Text>
                    )}
                </Flex>

                <Flex direction="column" gap={1}>
                    <Text fontWeight="bold">Link URL</Text>
                    <Input
                        name="creative.object_story_spec.link_data.link"
                        placeholder="Link URL"
                        bg="#fff"
                        size='sm'
                        borderRadius="md"
                        value={formik.values.creative.object_story_spec.link_data.link}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.creative?.object_story_spec?.link_data?.link && formik.errors.creative?.object_story_spec?.link_data?.link && (
                        <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.link}</Text>
                    )}
                </Flex>

                <Flex direction="column" gap={1}>
                    <Text fontWeight="bold">Call to action</Text>
                    <Input
                        name="creative.object_story_spec.link_data.call_to_action.type"
                        placeholder="Enter description"
                        size="sm"
                        borderRadius="md"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.creative?.object_story_spec?.link_data?.call_to_action?.type || ''}
                        isDisabled
                    />
                    {formik.touched?.creative?.object_story_spec?.link_data?.call_to_action?.type && formik.errors?.creative?.object_story_spec?.link_data?.call_to_action?.type && (
                        <Text fontSize="12px" color="red.500">{formik.errors.creative.object_story_spec.link_data.call_to_action.type}</Text>
                    )}
                </Flex>

                <Text fontWeight="bold">Creative</Text>
                <Flex direction="row" mb={4} gap={2} alignItems={'center'}>
                    <img
                        src={formik.values.image_url || ''} 
                        alt={formik.values.name}
                        style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                    />
                    <Button 
                        bg={'gray.50'}
                        size="xs"
                        borderWidth={1}
                        onClick={handleOpenDriveModal}>
                        {buttonLabel}
                    </Button>
                </Flex>
            </Flex>
            <Flex mt={4}>
                <Button
                    size="sm"
                    colorScheme="orange"
                    isLoading={formik.isSubmitting}
                    type="submit"
                    isDisabled={!formik.isValid}
                >
                    Save
                </Button>
            </Flex>

            {/* Drive Modal */}
            <DriveModal
                isDriveModalOpen={isDriveModalOpen}
                selectedAdAccounts={[{
                    id: adsAccountData._id,
                    adsAccountId: adsAccountData.adsAccountId,
                    adsAccountName: adsAccountData.adsAccountName,
                }]} 
                onClose={() => setIsDriveModalOpen(false)}
                onSubmit={handleMediaSelect} 
                uploadPurpose="update" 
                mediaType={isImage ? 'image' : 'video'}
            />
        </form>
    );
};

export default SingleAdUpdateForm;
