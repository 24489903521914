import {
    Box, Flex,
    Select,
    Text,
    Spinner,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useGetFacebookPagesQuery } from 'store/facebookServices/facebookServicesApi';

function FaceBookPageSelector({ adsAccount, onSelectedPage, selectedPageId, adData }) {

    const { adsAccountId, adsAccountName } = adsAccount || {};
    const [selectedPage, setselectedPage] = useState(selectedPageId || '');

    const { data: facebookPages, isLoading } = useGetFacebookPagesQuery(adsAccountId);

    useEffect(() => {
        setselectedPage(selectedPageId || '');
    }, [selectedPageId]);


    const handleEventPageChange = (event) => {
        const pageId = event.target.value;
        setselectedPage(pageId);
        onSelectedPage(pageId, adsAccountId);
    };

    return (
        <Box>
            <Flex gap={'20px'}>
                <Box flex={1}>
                    <Text fontWeight={'bold'} fontSize={'12px'}>{adsAccountName}</Text>
                    <Text fontSize={'12px'}>{adsAccountId}</Text>
                </Box>

                <Box flex={1}>
                    {isLoading ? (
                        <Spinner size="sm" />
                    ) : (
                        <Select
                            placeholder="Facebook page"
                            value={selectedPage}
                            onChange={handleEventPageChange}
                            borderRadius={'md'}
                            size={'sm'}
                        >
                            {facebookPages?.data?.map((page) => (
                                <option key={page.id} value={page.id}>
                                    {page.name}
                                </option>
                            ))}
                        </Select>
                    )}
                </Box>
            </Flex>
        </Box>
    )
}

export default FaceBookPageSelector