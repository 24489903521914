import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
    Box,
    Flex,
    Text,
    Input,
    Select,
    Button,
    Divider,
    useToast,
    Spinner,
    Icon,
    Tooltip,
} from '@chakra-ui/react';
import { useGetAdCreativeQuery, useUpdateAdMutation } from 'store/campaigns/adsApi';
import FaceBookPageSelector from 'components/adspresso/FaceBookPageSelector';
import { MdDelete } from 'react-icons/md';
import { useFormik } from 'formik';
import DriveModal from 'components/drive/DriveModal';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Ad name is required'),
    creative: Yup.object().shape({
        name: Yup.string().required('Creative name is required'),
        object_story_spec: Yup.object().shape({
            link_data: Yup.object().shape({
                message: Yup.string().required('Primary text is required'),
                link: Yup.string().url('Invalid URL').required('Website URL is required'),
                call_to_action: Yup.object().shape({
                    type: Yup.string().required('Call to action is required'),
                }),
                child_attachments: Yup.array().of(
                    Yup.object().shape({
                        link: Yup.string().url('Invalid URL').required('Website URL is required'),
                        name: Yup.string().required('Creative name is required'),
                        description: Yup.string().required('Description is required'),
                        image_hash: Yup.string().required('Image hash is required'),
                    })
                ).min(1, 'At least one creative is required'),
            }),
            page_id: Yup.string().required('Facebook Page ID is required'),
        }),
    }),
});

const CarouselAdUpdateForm = ({ adData, adsAccountData, onClose }) => {
    const toast = useToast();
    const { creative } = adData || {};

    const [isDriveModalOpen, setIsDriveModalOpen] = useState(false);
    const [selectedCreativeIndex, setSelectedCreativeIndex] = useState(null);

    const { data: adCreative } = useGetAdCreativeQuery(
        { creativeId: creative.id },
        { skip: !creative.id }
    );

    const [updateAd] = useUpdateAdMutation();
    
    //console.log(adCreative)

    const formik = useFormik({
        initialValues: {
            name: adData?.name || "",
            hash: adData?.hash || '',
            creative: {
                name: adCreative?.name || "",
                object_story_spec: {
                    link_data: {
                        message: adCreative?.object_story_spec?.link_data?.message || "",
                        link: adCreative?.object_story_spec?.link_data?.link || "",
                        call_to_action: {
                            type: adCreative?.object_story_spec?.link_data?.call_to_action?.type || "",
                        },
                        child_attachments: adCreative?.object_story_spec?.link_data?.child_attachments || [
                            { link: "", name: "", description: "", image_hash: "" ,video_id:"", picture:"", },
                        ],
                    },
                page_id: adCreative?.object_story_spec?.page_id || "",
                },
            },
        },
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {

            const payload = {
                name: values.name,
                hash:values.hash,
                creative: {
                    name: values.creative.name,
                    object_story_spec: {
                        link_data: {
                            message: values.creative.object_story_spec.link_data.message,
                            link: values.creative.object_story_spec.link_data.link,
                            call_to_action: {
                                type: values.creative.object_story_spec.link_data.call_to_action.type,
                            },
                            child_attachments: values.creative.object_story_spec.link_data.child_attachments.filter(child => child.link),
                        },
                        page_id: values.creative.object_story_spec.page_id,
                    },
                    degrees_of_freedom_spec: {
                        creative_features_spec: {
                            standard_enhancements: {
                                enroll_status : "OPT_OUT"
                            }
                        }
                    },
                    adsAccount: {
                        id: adsAccountData._id,
                        adsAccountId: adsAccountData.adsAccountId,
                        adsAccountName: adsAccountData.adsAccountName,
                    },
                },
            };

            updateAd({ creativeId: adData.id, payload })
                .unwrap()
                .then(response => {
                    toast({
                        position: "top-right",
                        title: "Ad updated",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .catch(error => {
                    toast({
                        position: "top-right",
                        title: "Error updating ad",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                    onClose();
                });
        },
    });

    const handleOpenDriveModal = (index) => {
        setIsDriveModalOpen(true);
        setSelectedCreativeIndex(index);
    };

    //console.log(formik.values.creative.object_story_spec.link_data.child_attachments)
    const isVideo = Boolean(formik.values.creative.object_story_spec.link_data.child_attachments[0].video_id);
    const buttonLabel = isVideo ? "Change Video" : "Change Image";
    
    const handleMediaSelect = (file) => {
        if (file && file.length > 0) {
            const selectedFile = file[0];
            
            // Update the specific creative in the Formik state
            formik.setFieldValue('creative.object_story_spec.link_data.child_attachments', formik.values.creative.object_story_spec.link_data.child_attachments.map((creative, index) => {
                if (index === selectedCreativeIndex) {
                    return {
                        ...creative,
                        ...(isVideo && {
                            video_id: selectedFile?.creativeId,
                            //hash: selectedFile?.hash,
                            picture: selectedFile?.picture,
                        }),
                        ...(!isVideo && {
                            //image_hash: selectedFile?.creativeId,
                            //hash: selectedFile?.hash,
                            picture: selectedFile?.url,
                        }),
                    };
                }
                return creative; 
            }));
        }
        setIsDriveModalOpen(false);
        setSelectedCreativeIndex(null);
    };

    // console.log(formik.values.creative.object_story_spec.link_data.child_attachments)
    
    if (!adCreative) {
        return <Spinner mt={'20px'} />;
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Flex direction={'column'}>
                <Flex direction={'column'} bg="gray.50" p={4} borderRadius="md" gap={4}>
                    {/* Ad Name Section */}
                    <Flex direction={'column'} gap={1}>
                        <Text fontWeight="bold">Ad name</Text>
                        <Input
                            name="name"
                            placeholder="Enter your ad name here..."
                            bg="#fff"
                            size='sm'
                            borderRadius="md"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name && (
                            <Text fontSize={"12px"} color="red.500">{formik.errors.name}</Text>
                        )}
                    </Flex>

                    {/* Ads Account Section */}
                    <Flex gap={'20px'}>
                        <Flex direction="column" gap={1} flex={1}>
                            <Text fontWeight="bold">Ads Account</Text>
                            <Input
                                bg="#e2e8f0"
                                value={adsAccountData.adsAccountName || ""}
                                isDisabled
                                size='sm'
                                borderRadius="md"
                            />
                        </Flex>

                        <Flex direction="column" gap={1}>
                            <Text fontWeight="bold">Facebook Page ID</Text>
                            <FaceBookPageSelector
                                adsAccount={adsAccountData}
                                selectedPageId={formik.values.creative.object_story_spec.page_id}
                                onSelectedPage={(pageId) => {
                                    formik.setFieldValue('creative.object_story_spec.page_id', pageId);
                                }}
                            />
                            {formik.errors.creative?.object_story_spec?.page_id && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.page_id}</Text>
                            )}
                        </Flex>
                    </Flex>

                    {/* Creative Attachments Section */}
                    {formik.values.creative.object_story_spec.link_data.child_attachments.map((creative, index) => {

                        return(
                        <Flex direction={'column'} key={index} borderWidth={1} p={4} borderRadius="md" gap={2}>
                            <Flex justifyContent="space-between" alignItems="center">
                                <Text fontWeight="bold">Headline</Text>
                                <Tooltip label="Delete" fontSize="xs">
                                    <Box>
                                        <Icon
                                            color={'red.500'}
                                            _hover={{ color: 'gray.400' }}
                                            as={MdDelete}
                                            cursor="pointer"
                                            boxSize={5}
                                            onClick={() => {
                                                const updatedAttachments = formik.values.creative.object_story_spec.link_data.child_attachments.filter((_, i) => i !== index);
                                                formik.setFieldValue('creative.object_story_spec.link_data.child_attachments', updatedAttachments);
                                            }}
                                        />
                                    </Box>
                                </Tooltip>
                            </Flex>
                            <Input
                                name={`creative.object_story_spec.link_data.child_attachments.${index}.name`}
                                placeholder="Creative name"
                                size='sm'
                                borderRadius="md"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={creative.name}
                            />
                            {formik.touched.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.name && formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.name && (
                                <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.name}</Text>
                            )}
                            
                            {/* Description Section */}
                            <Flex direction="column" gap={1}>
                                <Text fontWeight="bold">Description</Text>
                                <Input
                                    name={`creative.object_story_spec.link_data.child_attachments.${index}.description`}
                                    placeholder="Creative description"
                                    size='sm'
                                    borderRadius="md"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={creative.description}
                                />
                                {formik.touched.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.description && formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.description && (
                                    <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.description}</Text>
                                )}
                            </Flex>

                            {/* Link Section */}
                            <Flex direction="column" gap={1}>
                                <Text fontWeight="bold">Creative Link</Text>
                                <Input
                                    name={`creative.object_story_spec.link_data.child_attachments.${index}.link`}
                                    placeholder="Creative link"
                                    size='sm'
                                    borderRadius="md"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={creative.link}
                                />
                                {formik.touched.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.link && formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.link && (
                                    <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.child_attachments?.[index]?.link}</Text>
                                )}
                            </Flex>

                            <Text fontWeight="bold">Creative</Text>
                            <Flex direction="row" mb={4} gap={2} alignItems={'center'}>
                                <img
                                    src={creative?.video_id ? creative?.picture : creative?.picture || ''} 
                                    alt={creative?.name}
                                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                                />
                                <Button 
                                    bg={'gray.50'}
                                    size="xs"
                                    borderWidth={1}
                                    onClick={() => handleOpenDriveModal(index)}>
                                    {buttonLabel}
                                </Button>
                            </Flex>

                        </Flex>
                    )})}

                    <Button
                        onClick={() => {
                            const newAttachment = { link: "", name: "", description: "", image_hash: "", video_id:"",picture:"" };
                            formik.setFieldValue('creative.object_story_spec.link_data.child_attachments', [...formik.values.creative.object_story_spec.link_data.child_attachments, newAttachment]);
                        }}
                    >
                        Add Creative
                    </Button>

                    <Divider my={'20px'} />
                    <Box>
                        <Flex direction={'column'} gap={2}>
                            {/* Primary Text Section */}
                            <Flex direction="column" gap={1}>
                                <Text fontWeight="bold">Primary Text</Text>
                                <Input
                                    name="creative.object_story_spec.link_data.message"
                                    placeholder="Message"
                                    bg="#fff"
                                    size='sm'
                                    borderRadius="md"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.creative.object_story_spec.link_data.message}
                                />
                                {formik.touched.creative?.object_story_spec?.link_data?.message && formik.errors.creative?.object_story_spec?.link_data?.message && (
                                    <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.message}</Text>
                                )}
                            </Flex>

                            {/* Call to Action Section */}
                            <Flex direction="column" gap={1}>
                                <Text fontWeight="bold">Call to Action</Text>
                                <Select
                                    name="creative.object_story_spec.link_data.call_to_action.type"
                                    bg="#fff"
                                    size='sm'
                                    borderRadius="md"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isDisabled
                                    value={formik.values.creative.object_story_spec.link_data.call_to_action.type}
                                >
                                    <option value="SHOP_NOW">Shop Now</option>
                                </Select>
                                {formik.touched.creative?.object_story_spec?.link_data?.call_to_action?.type && formik.errors.creative?.object_story_spec?.link_data?.call_to_action?.type && (
                                    <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.call_to_action?.type}</Text>
                                )}
                            </Flex>

                            {/* Website URL Section */}
                            <Flex direction="column" gap={1}>
                                <Text fontWeight="bold">Website URL</Text>
                                <Input
                                    name="creative.object_story_spec.link_data.link"
                                    placeholder="Link"
                                    bg="#fff"
                                    size='sm'
                                    borderRadius="md"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.creative.object_story_spec.link_data.link}
                                />
                                {formik.touched.creative?.object_story_spec?.link_data?.link && formik.errors.creative?.object_story_spec?.link_data?.link && (
                                    <Text fontSize={"12px"} color="red.500">{formik.errors.creative?.object_story_spec?.link_data?.link}</Text>
                                )}
                            </Flex>
                        </Flex>
                    </Box>
                </Flex>
            </Flex>
            <Flex>
                <Button
                    size="sm"
                    colorScheme="orange"
                    isLoading={formik.isSubmitting}
                    isDisabled={!formik.isValid}
                    type="submit"
                    my={'20px'}
                >
                    Save
                </Button>
            </Flex>

             {/* Drive Modal */}
             <DriveModal
                isDriveModalOpen={isDriveModalOpen}
                selectedAdAccounts={[{
                    id: adsAccountData._id,
                    adsAccountId: adsAccountData.adsAccountId,
                    adsAccountName: adsAccountData.adsAccountName,
                }]} 
                onClose={() => setIsDriveModalOpen(false)}
                onSubmit={handleMediaSelect} 
                uploadPurpose="update" 
                mediaType={isVideo ? 'video' : 'image'}
            />
        </form>
    );
};

export default CarouselAdUpdateForm;
