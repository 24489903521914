import {
    Box,
    Button,
    Center,
    Checkbox,
    CheckboxGroup,
    Divider,
    Fade,
    Flex,
    FormControl,
    FormLabel,
    Icon,
    Image,
    Input,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Stack,
    Text,
    useToast
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { FiUpload } from 'react-icons/fi';
import { IoMdCloseCircle } from "react-icons/io";
import { useGetActiveAccountsListQuery } from 'store/adAccounts/adAccountsApi';
import {
    useFinishUploadMutation,
    useStartUploadSessionMutation, useUploadChunkMutation,
    useUploadImageMutation,
    useUploadVideoMutation,
} from 'store/driveUpload/uploadApi';
import { truncateName } from "utils/functions";
import { adUplaodSchema } from "utils/schema";
import { useDispatch, useSelector } from "react-redux";
import { setFiles, setIsWidgetOpen, updateUploadStatus, cancelUpload, setUploadAbortControllers } from "store/driveUpload/uploadSlice";
import { InfoOutlineIcon } from "@chakra-ui/icons";

// Constants
const MAX_IMAGE_UPLOAD_SIZE_MB = 30; // Max size for images
const MAX_VIDEO_UPLOAD_SIZE_GB = 4; // Max size for videos
const MAX_IMAGE_UPLOAD_SIZE_BYTES = MAX_IMAGE_UPLOAD_SIZE_MB * 1024 * 1024;
const MAX_VIDEO_UPLOAD_SIZE_BYTES = MAX_VIDEO_UPLOAD_SIZE_GB * 1024 * 1024 * 1024;
const chunkSize = 5 * 1024 * 1024;


const UploadModal = ({ isOpen, onClose, onSuccess, mediaType }) => {

    const toast = useToast();
    const dispatch = useDispatch();
    const [localFiles, setLocalFiles] = useState([]);
    const { files } = useSelector(state => state.upload);

    const [uploadImage, { isLoading: isImageLoading }] = useUploadImageMutation();
    const [uploadVideo, { isLoading: isVideoLoading }] = useUploadVideoMutation();

    const { data: activeAccountsData, isFetching: isActiveAccountsFetching, isLoading: isActiveAccountsDataLoading } = useGetActiveAccountsListQuery();
    const [startUploadSession] = useStartUploadSessionMutation();
    const [uploadChunk] = useUploadChunkMutation();
    const [finishUpload] = useFinishUploadMutation();

    // Formik setup
    const uploadFormik = useFormik({
        initialValues: {
            adsAccountName: [],
            files: [],
        },
        validationSchema: adUplaodSchema,
        onSubmit: async (values, { setSubmitting }) => {
            // Handle form submission
            if (values) {
                handleUploadAll(values.files);
                dispatch(setIsWidgetOpen(true));
                dispatch(setFiles([]));
                setLocalFiles([]);
            }
            setSubmitting(false);
            uploadFormik.resetForm();
            onClose();
        },
    });

    const onDrop = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            toast({
                title: "Upload Error",
                description: "The uploaded file exceeds the maximum size limit.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
            });
            return;
        }
    
        const validFiles = [];
        const newLocalFiles = []; // Array to store original File objects
    
        for (const file of acceptedFiles) {
            if (file.type.startsWith("image/") && file.size > MAX_IMAGE_UPLOAD_SIZE_BYTES) {
                toast({
                    title: "Upload Error",
                    description: `The image ${file.name} exceeds the maximum size of 30 MB.`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "top-right",
                });
            } else if (file.type.startsWith("video/") && file.size > MAX_VIDEO_UPLOAD_SIZE_BYTES) {
                toast({
                    title: "Upload Error",
                    description: `The video ${file.name} exceeds the maximum size of 4 GB.`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "top-right",
                });
            } else {
                validFiles.push({
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    thumbnail: URL.createObjectURL(file),
                    progress: 0,
                    status: "pending",
                    // file:file,
                });
                newLocalFiles.push({
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    thumbnail: URL.createObjectURL(file),
                    progress: 0,
                    status: "pending",
                    file:file,
                }); 
            }
        }
    
        if (validFiles.length > 0) {
            dispatch(setFiles(validFiles));
            setLocalFiles(newLocalFiles); // Update local state with original files
            uploadFormik.setFieldValue('files', newLocalFiles);
        }
    };

    const handleUploadAll = async (filesToUpload) => {
        console.log((window.navigator.onLine ? 'on' : 'off') + 'line');
    
        window.addEventListener('online', () => {
            toast({
                title: "You are back online!",
                status: "success",
                duration: 4000,
                isClosable: true,
                position: "top-right",
            });
        });
    
        window.addEventListener('offline', () => {
            toast({
                title: "You are offline. Some functionality may be unavailable.",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top-right",
            });
        });
    
        const uploadPromises = [];
    
        for (const file of filesToUpload) {
            const adsAccountsArray = uploadFormik?.values?.adsAccountName.map(accountId => {
                const selectedAdAccount = activeAccountsData?.data?.find(account => account?.adsAccountId === accountId);
                return selectedAdAccount ? {
                    id: selectedAdAccount?._id,
                    adsAccountId: selectedAdAccount?.adsAccountId,
                    adsAccountName: selectedAdAccount?.adsAccountName,
                } : null;
            }).filter(account => account !== null);
    
            if (adsAccountsArray.length === 0) {
                toast({
                    title: "Account Error",
                    description: "Please select a valid Ads Account.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "top-right",
                });
                return;
            }
    
            if (file.type.startsWith("image/")) {
                const uploadImagePromise = (async () => {
                    const formData = new FormData();
                    formData.append('filename', file.file);
                    formData.append('adsAccount', JSON.stringify(adsAccountsArray));
    
                    let progressInterval;
    
                    try {
                        const uploadDuration = 3000;
                        const interval = 100;
                        const totalSteps = uploadDuration / interval;
    
                        let progress = 0;
                        progressInterval = setInterval(() => {
                            if (progress < 100) {
                                progress += Math.round(100 / totalSteps);
                                adsAccountsArray.forEach(account => {
                                    dispatch(updateUploadStatus({ fileName: file.name, accountId: account.adsAccountId, status: "uploading", progress }));
                                });
                            }
                        }, interval);
    
                        const response = await uploadImage({ payload: formData }).unwrap();
    
                        clearInterval(progressInterval);
                        adsAccountsArray.forEach(account => {
                            dispatch(updateUploadStatus({ fileName: file.name, accountId: account.adsAccountId, status: "done", progress: 100 }));
                        });
                    } catch (error) {
                        clearInterval(progressInterval);
                        adsAccountsArray.forEach(account => {
                            dispatch(updateUploadStatus({ fileName: file.name, accountId: account.adsAccountId, status: "failed", progress: 0 }));
                        });
                        toast({
                            title: "Upload Error",
                            description: error?.data?.message,
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                            position: "top-right",
                        });
                    }
                })();
    
                uploadPromises.push(uploadImagePromise);
            } else if (file.type.startsWith("video/")) {
                adsAccountsArray.forEach(selectedAdAccount => {
                    const uploadVideoPromise = (async () => {
                        const abortController = new AbortController();
                        const controllerKey = `${file.name}-${selectedAdAccount.adsAccountId}`;
                        dispatch(setUploadAbortControllers({ [controllerKey]: abortController }));
    
                        try {
                            const startSessionData = await startUploadSessionWithRetry({
                                file,
                                adsAccount: selectedAdAccount.adsAccountId,
                            });
    
                            // Check for errors in start session data
                            if (startSessionData?.data?.status === 'error') {
                                const reason = startSessionData?.data?.message;
                                toast({
                                    title: "Upload Error",
                                    description: reason,
                                    status: "error",
                                    duration: 5000,
                                    isClosable: true,
                                    position: "top-right",
                                });
                                dispatch(updateUploadStatus({ fileName: file.name, accountId: selectedAdAccount.adsAccountId, status: "failed", progress: 0, reason }));
                                return; 
                            }
    
                            const initialProgress = 10;
                            dispatch(updateUploadStatus({ fileName: file.name, accountId: selectedAdAccount.adsAccountId, status: "uploading", progress: initialProgress }));
    
                            const chunks = splitFile(file);
                            const uploadState = loadUploadState(file.name) || {};
                            let allChunksUploaded = true;
    
                            for (let chunkIndex = 0; chunkIndex < chunks.length; chunkIndex++) {
                                if (abortController?.signal?.aborted) {
                                    dispatch(updateUploadStatus({ fileName: file.name, accountId: selectedAdAccount.adsAccountId, status: "cancelled", progress: 0, reason: 'Upload cancelled' }));
                                    allChunksUploaded = false;
                                    break;
                                }
                                const { chunk, start, end } = chunks[chunkIndex];
                                const chunkId = `${start}-${end}`;
    
                                if (uploadState[chunkId] === 'done') {
                                    continue;
                                }
    
                                try {
                                    await uploadChunkWithRetry({
                                        upload_session_id: startSessionData?.data?.upload_session_id,
                                        start_offset: start,
                                        end_offset: end,
                                        chunk,
                                        adsAccount: selectedAdAccount.adsAccountId,
                                    }, abortController);
    
                                    const progress = Math.round(((chunkIndex + 1) / chunks.length) * 90);
                                    dispatch(updateUploadStatus({ fileName: file.name, accountId: selectedAdAccount.adsAccountId, status: "uploading", progress: initialProgress + progress }));
                                    saveUploadState(file.name, chunkId, 'done');
    
                                } catch (uploadError) {
                                    allChunksUploaded = false;
                                    console.error(`Chunk ${chunkIndex} failed with error: ${uploadError.message}`);
                                    chunkIndex--; // Retry the same chunk
                                }
                            }
    
                            if (!allChunksUploaded) {
                                dispatch(cancelUpload({ fileName: file.name, accountId: selectedAdAccount.adsAccountId }));
                            }
    
                            if (allChunksUploaded && !abortController.signal.aborted) {
                                await finishUpload({
                                    upload_session_id: startSessionData?.data?.upload_session_id,
                                    file,
                                    video_id: startSessionData?.data?.video_id,
                                    payload: {
                                        adsAccount: {
                                            id: selectedAdAccount?.id,
                                            adsAccountId: selectedAdAccount?.adsAccountId,
                                            adsAccountName: selectedAdAccount?.adsAccountName
                                        }
                                    },
                                }).unwrap().then((response) => {
                                    onSuccess(response?.data?.creativeId);
                                });
                                dispatch(updateUploadStatus({ fileName: file.name, accountId: selectedAdAccount.adsAccountId, status: "done", progress: 100 }));
                                clearUploadState(file.name);
                            } else {
                                dispatch(updateUploadStatus({ fileName: file.name, accountId: selectedAdAccount.adsAccountId, status: "failed", progress: 0, reason: 'Upload Cancelled' }));
                            }
                        } catch (error) {
                            dispatch(updateUploadStatus({ fileName: file.name, accountId: selectedAdAccount.adsAccountId, status: "failed", progress: 0 }));
                        }
                    })();
    
                    uploadPromises.push(uploadVideoPromise);
                });
            }
        }
    
        // Wait for all uploads to complete
        await Promise.all(uploadPromises);
    };
    
    const startUploadSessionWithRetry = async (params, retries = 3) => {
        const { file, adsAccount } = params;
    
        for (let attempt = 0; attempt < retries; attempt++) {
            try {
                if (!navigator.onLine) {
                    await new Promise(resolve => {
                        window.addEventListener('online', () => resolve(), { once: true });
                    });
                }
    
                const startSessionData = await Promise.race([
                    startUploadSession({ file, adsAccount }).unwrap(),
                    new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 10000)), // 10 seconds timeout
                ]);
    
                return startSessionData; // Return successful response
    
            } catch (error) {
                if (attempt === retries - 1) {
                    throw error; // If last attempt, throw the error
                }
    
                const retryDelay = Math.pow(2, attempt) * 1000; // Exponential backoff
                await new Promise(resolve => setTimeout(resolve, retryDelay));
            }
        }
    };
    
    const uploadChunkWithRetry = async (params, abortController, retries = 3) => {
        const { upload_session_id, start_offset, end_offset, chunk, adsAccount } = params;
    
        for (let attempt = 0; attempt < retries; attempt++) {
            if (abortController.signal.aborted) {
                throw new Error('Upload cancelled');
            }
    
            try {
                if (!navigator.onLine) {
                    await new Promise(resolve => {
                        window.addEventListener('online', () => resolve(), { once: true });
                    });
                }
    
                const uploadResponse = await Promise.race([
                    uploadChunk({
                        upload_session_id,
                        start_offset,
                        end_offset,
                        chunk,
                        adsAccount,
                    }).unwrap(),
                    new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 10000)), // 10 seconds timeout
                ]);
    
                return uploadResponse; // If upload is successful, return the response
    
            } catch (error) {
                if (attempt === retries - 1) {
                    throw error; // Throw error if this was the last attempt
                }
    
                const retryDelay = Math.pow(2, attempt) * 1000; // Exponential backoff
                await new Promise(resolve => setTimeout(resolve, retryDelay));
            }
        }
    };
    
    
    

    const saveUploadState = (fileName, chunkId, status) => {
        const uploadState = JSON.parse(localStorage.getItem(`upload_state_${fileName}`)) || {};
        uploadState[chunkId] = status;
        localStorage.setItem(`upload_state_${fileName}`, JSON.stringify(uploadState));
    };

    const loadUploadState = (fileName) => {
        return JSON.parse(localStorage.getItem(`upload_state_${fileName}`)) || {};
    };

    const clearUploadState = (fileName) => {
        localStorage.removeItem(`upload_state_${fileName}`);
    };

    const splitFile = (file) => {
        const chunks = [];
        let start = 0;

        while (start < file?.file?.size) {
            const end = Math.min(start + chunkSize, file?.file?.size);
            const chunk = file?.file.slice(start, end);
            chunks.push({ start, end, chunk });
            start = end;
        }
        return chunks;
    };

    const removeFile = (index) => {
        const updatedFiles = localFiles.filter((_, i) => i !== index);
        dispatch(setFiles(updatedFiles));
        setLocalFiles(updatedFiles); 
        uploadFormik.setFieldValue('files', updatedFiles);
    };


    const {
        getRootProps,
        getInputProps,
        isDragActive,
        open
    } = useDropzone({
        onDrop,
        accept: mediaType === 'image' ? {
            "image/*": [".png", ".jpg", ".jpeg"]
        } : {
            "video/*": [".mp4", ".mov", ".avi", ".gif"]
        },
        multiple: true,
        maxFiles: 50,
        maxSize: mediaType === 'image' ? MAX_IMAGE_UPLOAD_SIZE_BYTES : MAX_VIDEO_UPLOAD_SIZE_BYTES,
        disabled: !uploadFormik.values.adsAccountName.length,
        noClick: true,
    });

    const showAdAccountError = !uploadFormik.values.adsAccountName.length;
    

    const handleModalClose = () => {
        uploadFormik.resetForm(); 
        dispatch(setFiles([]));   
        setLocalFiles([]);         
        onClose();              
    };
    

    return (
        <>
            <Modal isOpen={isOpen} onClose={() => handleModalClose()} size={{ base: "4xl" }}>
                <ModalOverlay />
                <ModalContent maxH="80vh">
                    <ModalHeader>
                        <Flex gap={2} direction={'column'}>
                            <Flex>
                                {mediaType === 'image' ? <Text>Upload images</Text> : <Text>Upload videos</Text>}
                            </Flex>
                            <Flex direction={'row'} gap={'20px'} align={'center'}>
                                <Box>
                                    <FormControl>
                                        <FormLabel fontWeight={'bold'}>Ad Accounts</FormLabel>
                                        <CheckboxGroup
                                            colorScheme={'orange'}
                                            id="adsAccount"
                                            value={uploadFormik.values.adsAccountName}
                                            onChange={(selectedValues) => {
                                                uploadFormik.setFieldValue('adsAccountName', selectedValues);
                                            }}                                            
                                        >
                                            <Stack spacing={'0px'}>
                                            {activeAccountsData?.data?.map((account) => (
                                                <Checkbox size={'sm'}  key={account.adsAccountId} id={`checkbox-${account.adsAccountId}`} value={account.adsAccountId}>
                                                    {account.adsAccountName}
                                                </Checkbox>
                                            ))}
                                            </Stack>
                                        </CheckboxGroup>
                                        {uploadFormik.touched.adsAccountName && uploadFormik.errors.adsAccountName && (
                                            <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>
                                                {uploadFormik.errors.adsAccountName}
                                            </Text>
                                        )}
                                    </FormControl>
                                </Box>
                            </Flex>
                        </Flex>
                    </ModalHeader>
                    <Divider mb={'10px'} />
                    <ModalBody overflowY="auto" maxH="calc(80vh - 120px)">
                        <Flex direction={'column'} gap={'20px'} minH={'40vh'}>
                            <Box flex={1}>
                                <FormControl>
                                    { !files.length ? (
                                        <Center
                                            className="dropzone"
                                            {...getRootProps({disabled: showAdAccountError})}
                                            sx={{
                                                textAlign: 'center',
                                                padding: '20px 0px',
                                                width: '100%',
                                                margin: '0 auto',
                                                borderWidth: '1px',
                                                borderColor: 'gray.600',
                                                borderStyle: 'dashed',
                                                background: 'gray.50',
                                                borderRadius: 5,
                                                minHeight: '320px'
                                            }}
                                            borderColor={isDragActive ? 'gray.500' : 'gray.600'}
                                        >
                                            <Input {...getInputProps()} />
                                            <Flex flexDirection={'column'} align={'center'} justify={'center'} gap={'10px'} minW={'220px'}>
                                                <Icon as={FiUpload} fontSize="2xl" color={'gray.800'} />
                                                <Text fontSize={{ base: '10px', md: '12px' }}>
                                                    Drag 'n' drop file or <Link textDecoration={'underline'} color={'gray.800'} onClick={open} size={'xs'}>
                                                        Browse
                                                    </Link>
                                                </Text>
                                            </Flex>
                                        </Center>
                                    ) : (
                                        <Fade in={files.length > 0}>
                                            <Flex flexDirection="row" flexWrap={'wrap'} align="flex-start" gap="30px" minW="220px" pos="relative">
                                                {files.map((file, index) => (
                                                    <Box key={file.name} role="group" position="relative" w="80px" h={'80px'} _hover={{ cursor: "pointer", transition: "all 0.3s ease" }}>
                                                        {file?.type.startsWith("video/") ? (
                                                            <video
                                                                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '3px' }}
                                                                src={file.thumbnail}
                                                                muted
                                                            />
                                                        ) : (
                                                            <Image
                                                                src={file.thumbnail}
                                                                alt={file.name}
                                                                objectFit="cover"
                                                                width="100%"
                                                                height="100%"
                                                                borderRadius="3px"
                                                            />
                                                        )}
                                                        <Icon
                                                            top={'-5px'}
                                                            right={'-5px'}
                                                            position="absolute"
                                                            color={'red.500'}
                                                            _hover={{ color: 'gray.400' }}
                                                            as={IoMdCloseCircle}
                                                            cursor="pointer"
                                                            boxSize={4}
                                                            onClick={() => removeFile(index)}
                                                            bg={'white'}
                                                            borderRadius={'50%'}
                                                        />
                                                        <Text fontSize={'11px'} mt={'2px'} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'}>{file?.name}</Text>
                                                    </Box>
                                                ))}
                                            </Flex>
                                        </Fade>
                                    )}
                                    {uploadFormik.touched.files && uploadFormik.errors.files && (
                                        <Text mt={'5px'} fontSize={'12px'} color={'red.600'}>
                                            {uploadFormik.errors.files}
                                        </Text>
                                    )}
                                </FormControl>
                            </Box>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Flex justifyContent={'space-between'} width={'100%'} gap={'20px'}>
                            <Box>
                                <Flex alignItems={'center'} gap={'5px'}>
                                    <InfoOutlineIcon color={'#ea7869'} pointerEvents="all" fontSize={'12px'} />
                                    <Text fontSize={'12px'}>Please select at least one Ad account to upload files.</Text>
                                </Flex>
                            </Box>
                            <Flex gap={4}>
                                <Button
                                    colorScheme={'orange'}
                                    size={'sm'}
                                    onClick={uploadFormik.handleSubmit}
                                    isDisabled={showAdAccountError || !uploadFormik.isValid}
                                >
                                    Submit
                                </Button>
                                <Button size={'sm'} onClick={() => { uploadFormik.resetForm(); onClose(); dispatch(setFiles([])); setLocalFiles([]); }}>
                                    Cancel
                                </Button>
                            </Flex>
                        </Flex> 
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default UploadModal;




