import { AddIcon, CloseIcon, InfoOutlineIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { FaPlayCircle } from "react-icons/fa";
import {
    useFetchImagesQuery, useFetchVideosQuery, useUploadImageMutation,
    useUploadVideoMutation,
} from 'store/driveUpload/uploadApi';
import { truncateName } from 'utils/functions';


function DriveModal({ isDriveModalOpen, selectedAdAccounts, mediaType, mediaFormat, onClose, onSubmit, uploadPurpose, adFormik }) {

    const inputRef = useRef();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showError, setShowError] = useState(false);

    const [uploadStatus, setUploadStatus] = useState({});
    const [videoUploadCompleted, setVideoUploadCompleted] = useState(false);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const selectedAdAccount = selectedAdAccounts[activeTabIndex];

    const { data: images, isLoading: isImagesDataLoading, isFetching: isImagesFetching } = useFetchImagesQuery(
        { currentPage, searchValue: debouncedSearchValue, adsAccountId: selectedAdAccount?.adsAccountId },
        { skip: !selectedAdAccount?.adsAccountId || mediaType !== 'image' }
    );

    const { data: videos, isLoading: isVideosDataLoading, isFetching: isVideosFetching, refetch: refetchVideos } = useFetchVideosQuery(
        { currentPage, searchValue: debouncedSearchValue, adsAccountId: selectedAdAccount?.adsAccountId },
        { skip: !selectedAdAccount?.adsAccountId || mediaType !== 'video' }
    );
    const [uploadImage, { isLoading: isImageLoading }] = useUploadImageMutation();
    const [uploadVideo, { isLoading: isVideoLoading }] = useUploadVideoMutation();

    const isAllAccountsSelected = selectedAdAccounts?.every(adAccount =>
        selectedFiles.some(file => file.adsAccount.adsAccountId === adAccount.adsAccountId)
    );

    useEffect(() => {
        // Reseting files when the modal opens
        if (isDriveModalOpen) {
            setSelectedFiles([]);
        }
    }, [isDriveModalOpen]);

    useEffect(() => {
        if (videoUploadCompleted) {
            setSpinnerVisible(true);
            const timer = setTimeout(() => {
                setSpinnerVisible(false);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [videoUploadCompleted]);

    //callback for video upload
    const handleVideoUploadSuccess = () => {
        setTimeout(() => {
            refetchVideos();
        }, 5000);
    }


    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchValue(searchValue);
        }, 800);

        return () => {
            clearTimeout(handler);
        };
    }, [searchValue]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchValue?.length > 2 || searchValue?.length === "") {
                setSearchValue(searchValue);
            }
        }, 4000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchValue]);

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false)
            }, 3000);
        }
    }, [showError])



    const handleSelection = (file) => {

        if (uploadPurpose === 'update') {
            //  console.log('update');
            setSelectedFiles([file]); // Only allow one file to be selected
        } else if (uploadPurpose === 'create') {
            // console.log('create');
            if (selectedFiles.includes(file)) {
                setSelectedFiles(selectedFiles.filter(f => f !== file)); // Deselect if already selected
            } else {
                setSelectedFiles([...selectedFiles, file]); // Add to selection
            }
        }
    };



    const handleDirectFileUpload = async (file) => {
        const formData = new FormData();

        if (file.type.startsWith("image/")) {
            // Process image upload
            const { _id, ...accountData } = selectedAdAccount;
            formData.append('filename', file);
            formData.append('adsAccount', JSON.stringify([accountData])); //sending array of objects for the images

            try {
                const response = await uploadImage({ payload: formData }).unwrap();
                //console.log(`Image upload successful for file ${file.name}`, response);
                setUploadStatus(prev => ({
                    ...prev,
                    [file.name]: { status: "done", progress: 100 },
                }));
            } catch (error) {
                console.error(`Error uploading image ${file.name}:`, error);
                setUploadStatus(prev => ({
                    ...prev,
                    [file.name]: { status: "failed", progress: 0 },
                }));
            }
        } else if (file.type.startsWith("video/")) {
            // Process video upload

            const { _id, ...accountData } = selectedAdAccount;
            formData.append('source', file);
            formData.append('adsAccount', JSON.stringify(accountData)); // Sending single object for video

            try {
                const response = await uploadVideo({ payload: formData }).unwrap();
                //console.log(`Video upload successful for file ${file.name}`, response);
                setUploadStatus(prev => ({
                    ...prev,
                    [file.name]: { status: "done", progress: 100 },
                }));
                setVideoUploadCompleted(true);
                handleVideoUploadSuccess();
            } catch (error) {
                console.error(`Error uploading video ${file.name}:`, error);
                setUploadStatus(prev => ({
                    ...prev,
                    [file.name]: { status: "failed", progress: 0 },
                }));
            }
        }
            
    }

    const imageList = images?.data?.map((file, index) => {
        const isSelected = selectedFiles.includes(file);

        return (
            <Flex direction={'column'} key={index}>
                <Flex
                    direction="column"
                    bgColor={'gray.50'}
                    borderRadius={'lg'}
                    borderBottomColor={'unset'}
                    borderBottomWidth={0}
                    pb={0}
                    border={isSelected ? '1px solid blue' : '1px solid transparent'}
                >
                    <Box
                        w="100%"
                        h="100px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                        borderRadius={2}
                        p={"2px"}
                        onClick={() => handleSelection(file)}
                    >
                        {file.url ? (
                            <Image
                                src={file.url}
                                alt={file.name}
                                objectFit="cover"
                                width="100%"
                                height="100%"
                                borderRadius="2px"
                            />
                        ) : (
                            null
                        )}
                    </Box>

                </Flex >
                <Text fontSize={'10px'} ml={2}>{truncateName(file.name)}</Text>
            </Flex>
        );
    })

    const videoList = videos?.data?.map((file, index) => {
        const isSelected = selectedFiles.includes(file);

        return (
            <Flex direction={'column'} key={index}>
                <Flex
                    direction="column"
                    bgColor={'gray.50'}
                    borderRadius={'lg'}
                    borderBottomColor={'unset'}
                    borderBottomWidth={0}
                    pb={0}
                    border={isSelected ? '1px solid blue' : '1px solid transparent'}
                >
                    <Box
                        w="100%"
                        h="100px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                        borderRadius={2}
                        p={"2px"}
                        onClick={() => handleSelection(file)}
                    >
                        {file?.source ? (
                            <Box w="100%" h="100%" position="relative">
                                <video
                                    style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '2px' }}
                                    src={file.source}
                                    muted
                                />
                                <Box
                                    position="absolute"
                                    top="50%"
                                    left="50%"
                                    transform="translate(-50%, -50%)"
                                    bg="rgba(0, 0, 0, 0.5)"
                                    borderRadius="50%"
                                >
                                    <FaPlayCircle color="white" size="35px" />
                                </Box>
                            </Box>
                        ) : (
                            null
                        )}
                    </Box>
                </Flex>
                <Text fontSize={'10px'} ml={2}>{truncateName(file.name)}</Text>
            </Flex>

        );
    })

    const selectedFileThumbnails = selectedFiles?.map((file, index) => {

        return (
            <Flex
                direction="column"
                key={index}
                bgColor={'gray.50'}
                borderRadius={'lg'}
                borderBottomColor={'unset'}
                borderBottomWidth={0}
                pb={0}

            >
                <Box
                    w="60px"
                    h="60px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    borderRadius={3}
                    p={"4px"}
                    onClick={() => handleSelection(file)}
                >
                    {file.url ? (
                        <Image
                            src={file.url}
                            alt={file.name}
                            objectFit="cover"
                            width="100%"
                            height="100%"
                            borderRadius="3px"
                        />
                    ) : (
                        null
                    )}

                    {file.source ? (
                        <Box w="40px" h="40px" position="relative">
                            <video
                                style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '3px' }}
                                src={file.source}
                                muted
                            />
                            <Box
                                position="absolute"
                                top="50%"
                                left="50%"
                                transform="translate(-50%, -50%)"
                                bg="rgba(0, 0, 0, 0.5)"
                                borderRadius="50%"
                            >
                                <FaPlayCircle color="white" size="15px" />
                            </Box>
                        </Box>
                    ) : (
                        null
                    )}
                </Box>
            </Flex >
        );
    })




    return (
        <>
            <Modal isOpen={isDriveModalOpen} onClose={onClose} size={'4xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{adFormik?.values?.format === 'carousel' ? 'Select up to 10 media' : 'Select media'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody minH={'60vh'}>
                        <Flex mb={'20px'} justifyContent={'flex-end'} gap={2} alignItems={'center'}>
                            <Box width={'100%'}>
                                <Tabs onChange={(index) => setActiveTabIndex(index)} isLazy>
                                    <TabList gap={"10px"}>
                                        {selectedAdAccounts.map((account, index) => (
                                            <Tab fontSize={'14px'} _selected={{ color: "brand.200", borderBottom: '2px' }} key={index}>{truncateName(account.adsAccountName)}</Tab>
                                        ))}
                                    </TabList>
                                    <TabPanels >
                                        {selectedAdAccounts.map((_, index) => (
                                            <TabPanel key={index} p={0} mt={'20px'}>
                                                <Flex mb={'20px'} justifyContent={'space-between'} gap={'20px'}>
                                                    <InputGroup size='sm'>
                                                        <InputLeftElement
                                                            pointerEvents='none'
                                                            children={<SearchIcon color='gray.300' />}
                                                        />
                                                        <Input
                                                            ref={inputRef}
                                                            type="text"
                                                            value={searchValue || ""}
                                                            onChange={e => {
                                                                setSearchValue(inputRef.current.value);
                                                            }}
                                                            placeholder={`Search...`}
                                                        />
                                                        {searchValue &&
                                                            <InputRightElement
                                                                cursor={'pointer'}
                                                                children={isImagesFetching || isVideosFetching ? <Spinner /> : <CloseIcon fontSize={14} _hover={{ color: 'gray.600' }} color='gray.300' />}
                                                                onClick={() => { setSearchValue(''); setShowError(false); }} />}
                                                    </InputGroup>
                                                    {showError ? (
                                                        <Flex flexDirection={'column'} gap={'10px'} justifyContent={'center'} alignItems={'center'}>
                                                            <Text mt={4} fontSize={'sm'} color='red.500'>No results found. Try a different search term.</Text>
                                                        </Flex>
                                                    ) : null}
                                                    <Flex mb={'0px'} justifyContent={'flex-end'} >
                                                        <FormControl display={'flex'} justifyContent={'flex-end'}>
                                                            <FormLabel htmlFor="file-upload" mb="0" display={'flex'} justifyContent={'flex-end'}>
                                                                <Button as={'span'} colorScheme="orange" size={'sm'} >
                                                                    <AddIcon boxSize={3} />
                                                                    &nbsp;{isImageLoading || (isVideoLoading || spinnerVisible) ? (
                                                                        <Spinner size={'xs'} />
                                                                    ) : 'Upload'}
                                                                </Button>
                                                            </FormLabel>
                                                            <input
                                                                id="file-upload"
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                accept={mediaType === 'image' ? 'image/*' : mediaType === 'video' ? 'video/*' : '*/*'}
                                                                onChange={(e) => { handleDirectFileUpload(e.target.files[0]) }}
                                                            />
                                                        </FormControl>
                                                    </Flex>
                                                </Flex>
                                                <Box >
                                                    {mediaType === 'image' ? (
                                                        <>
                                                            {(isImagesDataLoading || isImagesFetching) ? (
                                                                <Spinner mt={'20px'} />
                                                            ) : (
                                                                <>
                                                                    {images?.data?.length > 0 ? (
                                                                        <Grid templateColumns={'repeat( auto-fit, minmax(100px, 100px) )'} gap='10px'>
                                                                            {imageList}
                                                                        </Grid>
                                                                    ) : (
                                                                        <Text>No Images yet</Text>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : null}
                                                    {mediaType === 'video' ? (
                                                        <>
                                                            {isVideosDataLoading || isVideosFetching ? (
                                                                <Spinner mt={'20px'} />
                                                            ) : (
                                                                <>
                                                                    {videos?.data?.length > 0 ? (
                                                                        <Grid templateColumns={'repeat( auto-fit, minmax(100px, 100px) )'} gap='10px'>
                                                                            {videoList}
                                                                        </Grid>
                                                                    ) : (
                                                                        <Text>No videos yet</Text>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : null}
                                                </Box>
                                            </TabPanel>
                                        ))}
                                    </TabPanels>
                                </Tabs>
                            </Box>
                        </Flex>
                    </ModalBody>

                    <ModalFooter mt={'20px'}>
                        <Flex direction={'column'} justifyContent={'flex-start'} width={'100%'} gap={'20px'}>
                            {mediaFormat === 'CAROUSEL' && selectedFiles?.length > 0 ? (
                                <Flex justifyContent={'flex-start'} gap={'10px'} direction={'column'} bg={'gray.50'} p={'10px'}>
                                    <Text fontSize={'12px'}>Selected {mediaType}{mediaFormat === 'CAROUSEL' ? 's' : null} </Text>
                                    <Flex gap={'5px'}>
                                        {selectedFileThumbnails}
                                    </Flex>
                                </Flex>
                            ) : null}

                            <Flex justifyContent={'space-between'}>
                                <Box>
                                    {!isAllAccountsSelected ? (
                                        <Flex alignItems={'center'} gap={'5px'}>
                                            <InfoOutlineIcon color={'#ea7869'} pointerEvents="all" fontSize={'12px'} />
                                            <Text fontSize={'12px'}>  Please select at least single media from single ad account</Text>
                                        </Flex>
                                    ) : null}
                                </Box>
                                <Flex>
                                    <Button mr={3} onClick={onClose} size={'sm'}>
                                        Close
                                    </Button>
                                    <Button
                                        colorScheme={'orange'}
                                        size={'sm'}
                                        isDisabled={selectedFiles?.length === 0 || !isAllAccountsSelected}
                                        onClick={() => {
                                            if (selectedFiles?.length > 0) {
                                                onSubmit(selectedFiles, mediaType);
                                                onClose();
                                            }
                                        }}
                                    >Submit</Button>
                                </Flex>
                            </Flex>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DriveModal