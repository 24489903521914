import { CalendarIcon, ChevronDownIcon, ChevronUpIcon, InfoOutlineIcon, TimeIcon } from '@chakra-ui/icons';
import { Box, Button, Checkbox, Collapse, Divider, Flex, Input, InputGroup, InputRightAddon, Radio, RadioGroup, Select, Stack, Text, Tooltip, useToast } from '@chakra-ui/react';
import AgeGenderSelector from 'components/adspresso/AgeGenderSelector';
import DetailedTargetingSelector from 'components/adspresso/DetailedTargetingSelector';
import GeoLocationsSelector from 'components/adspresso/GeoLocationsSelector';
import LocaleSelector from 'components/adspresso/LocaleSelector';
import PlacementsSelector from 'components/adspresso/PlacmentsSelector';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useUpdateAdsetMutation } from 'store/campaigns/adsetFormApi';
import { flattenTargeting, formatUnixTimestamp, removeEmptyValues } from 'utils/functions';
import { leadFormUpdateSchema } from 'utils/schema';
import { useGetActiveAccountsListQuery } from 'store/adAccounts/adAccountsApi';

function pluralizeType(type) {
    const pluralMapping = {
        country: 'countries',
        city: 'cities',
        region: 'regions',
        zip: 'zips',
        neighborhood: 'neighborhoods',
        geo_market: 'geo_markets',
        electoral_district: 'electoral_districts',
        subcity: 'subcities',
        place: 'places',
        address: 'addresses'
    };
    return pluralMapping[type] || `${type}s`; // Default pluralize rule by appending 's'
}


const transformGeolocations = (locations) => {
    const geoLocations = {};
    const excludedGeoLocations = {};

    locations.forEach(location => {
        const pluralizedType = pluralizeType(location.type);

        // Determine whether to add to inclusions or exclusions
        //exclude neighborhood type


        if (location.inclusionType === 'include') {
            if (!geoLocations[pluralizedType]) {
                geoLocations[pluralizedType] = [];
            }
            geoLocations[pluralizedType].push(location);
        } else if (location.inclusionType === 'exclude') {
            if (!excludedGeoLocations[pluralizedType]) {
                excludedGeoLocations[pluralizedType] = [];
            }
            excludedGeoLocations[pluralizedType].push(location);
        }
    });

    let nonEmptyGeoLocations = Object.keys(geoLocations)?.length ? { geo_locations: geoLocations } : null;
    let nonEmptyExcludedGeoLocations = Object.keys(excludedGeoLocations)?.length ? { excluded_geo_locations: excludedGeoLocations } : null;

    return {
        ...nonEmptyGeoLocations,
        ...nonEmptyExcludedGeoLocations
    };
};

const transformDetailedTargets = (detailedTargets) => {
    const result_object = {};

    detailedTargets?.forEach(item => {
        const { type } = item;
        if (!result_object[type]) {
            result_object[type] = [];
        }
        result_object[type].push(item);
    });

    return { ...result_object };
};


function LeadUpdateForm({ data, adsetsListData, campaignData, selectedAdset, onEditModalClose, onClose, onSaveSuccess, context }) {

    const toast = useToast();
    const { name: campaignName, adsAccounts, objective, buying_type } = campaignData || {};

    const currentAdsetData = selectedAdset;

    const firstAdAccountId = campaignData?.adsAccount?.adsAccountId;

    const [startDate, setStartDate] = useState(currentAdsetData?.start_time ? new Date(currentAdsetData?.start_time * 1000) : new Date());
    const [endDate, setEndDate] = useState(currentAdsetData?.end_time ? new Date(currentAdsetData?.end_time * 1000) : new Date());
    const [errorDateMessage, setErrorDateMessage] = useState('');

    const [showEndDate, setShowEndDate] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const [placements, setPlacements] = useState({});
    const [geoLocations, setGeoLocations] = useState({});
    const [ageGender, setAgeGender] = useState({
        age_min: currentAdsetData?.targeting?.age_min ? currentAdsetData?.targeting?.age_min : 18,
        age_max: currentAdsetData?.targeting?.age_max ? currentAdsetData?.targeting?.age_min : 65,
        genders: currentAdsetData?.targeting?.gender ? currentAdsetData?.targeting?.gender : []
    });
    const genderMap = { all: [0], men: [1], women: [2] };
    const [interests, setInterests] = useState([]);
    const [budgetType, setBudgetType] = useState(currentAdsetData?.daily_budget > 0 ? 'daily_budget' : 'lifetime_budget');
    const { data: activeAccountsData } = useGetActiveAccountsListQuery();
    const [updateAdset, { isLoading: isAdsetUpdating }] = useUpdateAdsetMutation();

    const adSetFormik = useFormik({
        initialValues: {
            name: currentAdsetData?.name || '',
            destination_type: currentAdsetData?.destination_type || 'WEBSITE',
            optimization_goal: currentAdsetData?.optimization_goal || 'OFFSITE_CONVERSIONS',
            billing_event: currentAdsetData?.billing_event || 'IMPRESSIONS',
            bid_strategy: currentAdsetData?.bid_strategy || 'LOWEST_COST_WITHOUT_CAP',
            bid_amount: Number(currentAdsetData?.bid_amount) / 100 || '',
            budget: currentAdsetData?.daily_budget && currentAdsetData?.daily_budget > 0 ? 'daily_budget' : 'lifetime_budget',
            daily_budget: Number(currentAdsetData?.daily_budget) / 100 || 800,
            lifetime_budget: Number(currentAdsetData?.lifetime_budget) / 100 || 14000,
            geo_locations: currentAdsetData?.targeting?.geo_locations ? Object.values(currentAdsetData?.targeting?.geo_locations).flat() : [] || [],
            locales: currentAdsetData?.targeting?.locales || [],
            context: 'adsetEdit',
        },
        enableReinitialize: true,
        validationSchema: leadFormUpdateSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const { budget, name, bid_strategy, destination_type, optimization_goal, billing_event, daily_budget, bid_amount, lifetime_budget, geo_locations, locales } = values;


            const bidDetails = bid_strategy === 'LOWEST_COST_WITH_BID_CAP'
                ? { bid_amount: bid_amount * 100 }
                : {}


            const selectedAdsetData = adsetsListData?.data?.find(adset => adset._id === selectedAdset);
            const targetingId =  selectedAdsetData ? selectedAdsetData.targeting?._id : undefined;
            const transformedDetailedTargets = transformDetailedTargets(interests)

        

            const geoLocationList = geo_locations?.length ? transformGeolocations(geo_locations) : null;
            const genderList = ageGender?.genders?.length > 0 ? { genders: ageGender.genders } : null;
            const detailedTargetList = interests?.length > 0 ? transformedDetailedTargets : null;
            const filteredPlacements = Object.fromEntries(
                Object.entries(placements).filter(([_, value]) => value.length > 0)
            );

            const localeList = locales?.length > 0 ? { locales: locales } : null;

            const payload = {
                name,
                destination_type, optimization_goal, billing_event,
                bid_strategy,
                campaign: selectedAdset?.campaign_id,
                targeting: {
                    ...(targetingId ? { _id: targetingId } : {}),
                    ...filteredPlacements,
                    age_min: ageGender.age_min,
                    age_max: ageGender.age_max,
                    ...geoLocationList,
                    ...genderList,
                    ...detailedTargetList,
                    ...localeList
                },
                ...bidDetails,
                currency : activeAccountsData?.data[0]?.currency,
                ...(budget === 'daily_budget' ? { daily_budget: Number(daily_budget) * 100 } : {}),
                ...(budget === 'lifetime_budget' ? { lifetime_budget: Number(lifetime_budget) * 100 } : {}),
                //start_time: startDate ? moment(startDate).unix() : 0,
                ...(budget === 'lifetime_budget' ? { end_time: endDate ? moment(endDate).unix() : 0 } : {}),
                ...(budget === 'daily_budget' && showEndDate ? { end_time: endDate ? moment(endDate).unix() : 0 } : {}),
            };

            const filteredPayload = removeEmptyValues(payload);

            updateAdset({ payload: filteredPayload, adsetId: selectedAdset?.id,})
                .unwrap()
                .then(response => {
                    toast({
                        position: "top-right",
                        title: "Ad set updated",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                    onEditModalClose();
                    resetForm();
                    onSaveSuccess();
                    onClose();
                })
                .catch(error => {
                    let errorMessage = "An error occurred while updating the adset.";
                    if (error && error.data && error.data.message) {
                        errorMessage = error.data.message.replace(/"/g, '');
                    } else if (error.message) {
                        errorMessage = error.message.replace(/"/g, '');
                    }
                    toast({
                        position: "top-right",
                        title: "Error updating Adset",
                        description: errorMessage,
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                });
            }
        },
    );
   
    const {
        name,
        destination_type,
        optimization_goal,
        budget,
        targeting,
        bid_strategy
    } = data;

    const optimizationOptions = optimization_goal[adSetFormik.values.destination_type] || [];

    useEffect(() => {
        const defaultDestinationType = adSetFormik.values.destination_type;
        const optimizationOptions = data.optimization_goal[defaultDestinationType] || [];
        const defaultOptimizationGoal = optimizationOptions.length ? optimizationOptions[0].value : '';
    }, [adSetFormik.values.destination_type]);


    useEffect(() => {
        // Update bid strategy based on optimization_goal
        adSetFormik.setFieldValue('bid_strategy', currentAdsetData?.bid_strategy || 'LOWEST_COST_WITHOUT_CAP');
    }, [currentAdsetData?.bid_strategy]);

    useEffect(() => {
        if (adSetFormik.values.optimization_goal !== currentAdsetData?.optimization_goal) {
            adSetFormik.setFieldValue('bid_amount', '');
            adSetFormik.setFieldValue('bid_strategy', 'LOWEST_COST_WITHOUT_CAP');
        } else {
            adSetFormik.setFieldValue('bid_strategy', currentAdsetData?.bid_strategy);
        }
    }, [adSetFormik.values.optimization_goal])

    useEffect(() => {
        if (currentAdsetData?.optimization_goal) {
            adSetFormik.setFieldValue('optimization_goal', currentAdsetData?.optimization_goal || 'OFFSITE_CONVERSIONS');
        }
    }, [currentAdsetData?.optimization_goal]);

    useEffect(() => {
        if (adSetFormik.values.destination_type == "PHONE_CALL") {
            adSetFormik.setFieldValue('optimization_goal', 'QUALITY_CALL');
            adSetFormik.setFieldValue('bid_strategy', 'LOWEST_COST_WITHOUT_CAP');
            // adSetFormik.setFieldValue('bid_amount', '');
            // adSetFormik.setFieldValue('roas_average_floor', '');
        }
        if (adSetFormik.values.destination_type === "WEBSITE") {
            // Update the optimization goal only if it's not already set
            if (!currentAdsetData?.optimization_goal) {
                adSetFormik.setFieldValue('optimization_goal', 'OFFSITE_CONVERSIONS');
            }
            else if (adSetFormik.values.optimization_goal === "QUALITY_CALL") {
                adSetFormik.setFieldValue('optimization_goal', 'OFFSITE_CONVERSIONS');
            }
        }
    }, [adSetFormik.values.destination_type]);

    useEffect(() => {
        if (adSetFormik.values.budget === 'lifetime_budget') {
            if (currentAdsetData?.end_time) {
                setEndDate(new Date(currentAdsetData?.end_time * 1000))
            } else {
                const newEndDate = new Date(startDate);
                newEndDate.setDate(newEndDate.getDate() + 30); // 30 days from startDate
                setEndDate(newEndDate);
            }
        }
    }, [adSetFormik.values.budget]);

    useEffect(() => {
        if (currentAdsetData?.daily_budget && currentAdsetData?.end_time) {
            setShowEndDate(true);
            setEndDate(new Date(currentAdsetData.end_time * 1000));
        } else {
            setShowEndDate(false);
        }
    }, [currentAdsetData]);

    useEffect(() => {
        if (endDate <= startDate) {
            setErrorDateMessage('End date must be greater than start date.');
        } else {
            setErrorDateMessage('');
        }
    }, [startDate, endDate]);

    const handleBudgetChange = (e) => {
        const selectedBudgetType = e.target.value;
        setBudgetType(selectedBudgetType); 
    
        // Adjust budget values based on selection
        if (selectedBudgetType === 'daily_budget') {
            adSetFormik.setFieldValue('lifetime_budget', Number(adSetFormik.values.lifetime_budget));
        } else if (selectedBudgetType === 'lifetime_budget') {
            adSetFormik.setFieldValue('daily_budget', Number(adSetFormik.values.daily_budget));
        }
        adSetFormik.setFieldValue('budget', selectedBudgetType);
    };

    const handleLocationsChange = (locations) => {
        setGeoLocations(locations)
        adSetFormik.setFieldValue('geo_locations', locations);
    };

    const handleAgeGenderChange = (data) => {
        setAgeGender(prevState => {
            const newState = {
                age_min: data.age_min,
                age_max: data.age_max,
                genders: genderMap[data.gender] || []
            };
            return newState;
        });
    };

    const handleInterestsChange = (interests) => {
        setInterests(interests)
    };

    const handleSave = () => {
        // Set all fields to touched to trigger validation
        Object.keys(adSetFormik.values).forEach(field => {
            adSetFormik.setFieldTouched(field, true);
        });
    
        const errors = adSetFormik.errors;
        const errorKeys = Object.keys(errors);
    
        // Check for validation errors
        if (errorKeys.length > 0) {
            const firstErrorKey = errorKeys[0];
    
            // First, try to scroll to the element by name
            const errorElementByName = document.querySelector(`[name="${firstErrorKey}"]`);
            if (errorElementByName) {
                errorElementByName.scrollIntoView({ behavior: 'smooth', block: 'center' });
                return; 
            }

            // If not found, try to scroll to the element by error class
            const errorElementByClass = document.querySelector(`.error-class-${firstErrorKey}`);
            if (errorElementByClass) {
                errorElementByClass.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            return; 
        }
    
        if (
            (adSetFormik.values.budget === 'lifetime_budget' && (errorDateMessage || endDate <= startDate)) ||
            (adSetFormik.values.budget === 'daily_budget' && showEndDate && (errorDateMessage || endDate <= startDate))
        ) {
            return;
        }
    
        adSetFormik.handleSubmit();
    };

    return (
        <Box borderRadius="md" width="100%" maxWidth="600px">
            <form onSubmit={adSetFormik.handleSubmit}>
                {/* Ad Set Name */}
                <Flex direction="column" mb={4} bg="gray.50" p={4} borderRadius="md" gap={1}>
                    <Flex>
                        <Text fontWeight="bold">{name.label}</Text>
                        <Text display="inline-flex" fontSize="18px" color="red.400">*</Text>
                    </Flex>
                    <Input
                        name="name"
                        placeholder={`Enter ${name.label}`}
                        bg="#fff"
                        value={adSetFormik.values.name}
                        onChange={adSetFormik.handleChange}
                        onBlur={adSetFormik.handleBlur}
                        borderRadius="md"
                        size={'sm'}
                    />
                    {adSetFormik.touched.name && adSetFormik.errors.name && (
                        <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.name}</Text>
                    )}
                </Flex>


                {/* Conversion Location */}
                <Flex direction="column" mb={4} bg="gray.50" p={4} borderRadius="md" gap={2}>
                    <Text fontWeight="bold">{destination_type.label1}</Text>
                    <Text fontWeight="bold">{destination_type.label2}</Text>
                    <RadioGroup
                        name="destination_type"
                        onChange={(value) => {
                            adSetFormik.setFieldValue('destination_type', value)
                            // adSetFormik.setFieldValue('promoted_object', [])
                        }}
                        value={adSetFormik.values.destination_type}
                        isDisabled={context == 'adsetEdit'}
                    >
                        <Stack spacing={4} direction='column' mb={2}>
                            {destination_type.options?.map((option) => (
                                <Flex key={option.value} align="center">
                                    <Radio colorScheme='orange' value={option.value} />
                                    <Stack ml={2} spacing={0}>
                                        <Text>{option.label1}</Text>
                                        <Text fontSize='sm'>{option.label2}</Text>
                                    </Stack>
                                </Flex>
                            ))}
                        </Stack>
                    </RadioGroup>
                    {adSetFormik.touched.destination_type && adSetFormik.errors.destination_type && (
                        <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.destination_type}</Text>
                    )}

                    {adSetFormik.values.destination_type && (
                        <Flex direction="column" gap={2} mb={2}>
                            <Flex alignItems={'center'}>
                                <Text fontWeight="bold">{optimization_goal.label}</Text>
                                <Tooltip
                                    borderRadius={5}
                                    label={
                                        <Box fontSize="sm" p={'5px'}>
                                            <Text textAlign={'justify'}>Choose what you want people to do when they see your ads. Your campaign objective should align with your overall business goals.</Text>
                                        </Box>
                                    }
                                    fontSize="md"
                                >
                                    <InfoOutlineIcon pointerEvents="all" ml={2} />
                                </Tooltip>
                            </Flex>

                            {optimizationOptions.length === 1 ? (
                                <Text bg="#fff" borderRadius="md" p={2} fontSize={'14px'} height={'32px'} display="flex" alignItems="center">
                                    {optimizationOptions[0].label}
                                </Text>
                            ) : (
                                <Select
                                    name="optimization_goal"
                                    value={adSetFormik.values.optimization_goal}
                                    onChange={(e) => adSetFormik.setFieldValue('optimization_goal', e.target.value)}
                                    onBlur={adSetFormik.handleBlur}
                                    bg="#fff"
                                    borderRadius="md"
                                    size={'sm'}
                                    isDisabled
                                >
                                    {optimizationOptions.map((option, index) => (
                                        <option key={`${option.value}-${index}`} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}

                                </Select>
                            )}
                            {adSetFormik.touched.optimization_goal && adSetFormik.errors.optimization_goal && (
                                <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.optimization_goal}</Text>
                            )}
                        </Flex>
                    )}

                    {/* Bid Amount Field */}
                    <Flex direction="column">
                        {adSetFormik.values.optimization_goal &&
                            bid_strategy?.option[1]?.optimization_goal.includes(adSetFormik.values.optimization_goal) && (
                                <Flex direction="column">
                                    <Flex alignItems={'center'}>
                                        <Text mb={2} fontWeight="bold">
                                            Cost per result goal{" "}
                                            <Text as="span" fontWeight="normal">Optional</Text>
                                        </Text>
                                        <Tooltip
                                            borderRadius={5}
                                            label={
                                                <Box fontSize="sm" p={'5px'}>
                                                    <Text textAlign={'justify'}>
                                                        Set a goal if you're aiming for a certain cost per result. To set your goal,
                                                        use cost per result data from previous campaigns with the same optimisation event
                                                        and attribution settings. If you don't set a cost per result goal, Meta will focus
                                                        on spending your entire budget and getting the most results.<br></br>
                                                        Cost per result goal is only available with some performance goal selections.
                                                    </Text>
                                                </Box>
                                            }
                                            fontSize="md"
                                        >
                                            <InfoOutlineIcon pointerEvents="all" ml={2} mb={2} />
                                        </Tooltip>
                                    </Flex>

                                    <InputGroup>
                                        <Input
                                            name="bid_amount"
                                            type="number"
                                            placeholder="₹X.XX"
                                            value={adSetFormik.values.bid_amount}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                adSetFormik.handleChange(e);
                                                if (value) {
                                                    adSetFormik.setFieldValue('bid_strategy', 'LOWEST_COST_WITH_BID_CAP');
                                                } else {
                                                    adSetFormik.setFieldValue('bid_strategy', 'LOWEST_COST_WITHOUT_CAP');
                                                }
                                            }}
                                            onBlur={adSetFormik.handleBlur}
                                            borderRadius={'md'}
                                            size={'sm'}
                                            onWheel={(e) => e.target.blur()} 
                                        />
                                        <InputRightAddon height={'32px'} fontSize={'14px'}>
                                            {activeAccountsData?.data[0]?.currency}
                                        </InputRightAddon>
                                    </InputGroup>

                                    {adSetFormik.touched.bid_amount && adSetFormik.errors.bid_amount && (
                                        <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.bid_amount}</Text>
                                    )}
                                </Flex>
                            )}
                        {/* Conditionally render the bid strategy radio options if bid_amount has a value */}
                        {(adSetFormik.values.bid_amount) && (adSetFormik.values.destination_type == "WEBSITE") && (
                            <Flex direction="column" mt={4}>
                                <Text mb={2} fontWeight="bold">Bid strategy</Text>
                                <RadioGroup
                                    name="bid_strategy"
                                    value={adSetFormik.values.bid_strategy}
                                    onChange={(value) => adSetFormik.setFieldValue('bid_strategy', value)}
                                >

                                    <Stack direction="row" spacing={4}>
                                        <Flex direction="row" align="center" spacing={2}>
                                            <Radio colorScheme='orange' value="LOWEST_COST_WITH_BID_CAP" />
                                            <Flex direction="column" ml={2}>
                                                <Text fontWeight="bold">Cost per result goal</Text>
                                                <Text fontSize="sm" color="gray.500">Best for getting the most volume</Text>
                                            </Flex>
                                        </Flex>
                                    </Stack>
                                </RadioGroup>
                            </Flex>
                        )}
                    </Flex>
                </Flex>

                {/* Budget Scheduling */}
                <Flex bg="gray.50" p={4} flexDirection="column" borderRadius="md" gap={4} mt={4}>
                    <Text fontWeight="bold">Budget & schedule</Text>
                    {/* Budget */}
                    <Flex alignItems={'center'}>
                        <Text fontWeight="bold">{budget.label}</Text>
                        <Tooltip
                            borderRadius={5}
                            label={
                                <Box fontSize="sm" p={'5px'}>
                                    <Text textAlign={'justify'}>
                                        Your ad set budget is the daily or lifetime amount that you want to spend on this ad set.
                                        A daily budget is the average that you'll spend every day. A lifetime budget is the maximum that you'll spend during the lifetime of your ad set.
                                    </Text>
                                </Box>
                            }
                            fontSize="md"
                        >
                            <InfoOutlineIcon pointerEvents="all" ml={2} />
                        </Tooltip>
                    </Flex>
                    <Flex>
                        <Select
                            name="budget"
                            value={budgetType}
                            onChange={handleBudgetChange}
                            onBlur={adSetFormik.handleBlur}
                            bg="#fff"
                            borderRadius={'md'}
                            borderRight={'none'}
                            borderRightRadius="0"
                            maxW={'200px'}
                            size={'sm'}
                        >
                            {budget.options?.map((option) => (
                                <option key={option.value} value={option.value} disabled={option.value !== budgetType}>
                                    {option.label}
                                </option>
                            ))}
                        </Select>
                        {budgetType === 'daily_budget' && (
                            <InputGroup>
                                <Input
                                    name="daily_budget"
                                    placeholder='₹'
                                    type="number"
                                    value={String(adSetFormik.values.daily_budget)}
                                    onChange={(e) => adSetFormik.setFieldValue('daily_budget', e.target.value)}
                                    onBlur={adSetFormik.handleBlur}
                                    borderRight="0"
                                    borderLeftRadius="none"
                                    size={'sm'}
                                    onWheel={(e) => e.target.blur()} 
                                />
                               <InputRightAddon height={'32px'} fontSize={'14px'}>
                                            {activeAccountsData?.data[0]?.currency}
                               </InputRightAddon>
                            </InputGroup>
                        )}
                        {budgetType === 'lifetime_budget' && (
                            <InputGroup>
                                <Input
                                    name="lifetime_budget"
                                    placeholder='₹'
                                    type="number"
                                    value={adSetFormik.values.lifetime_budget}
                                    onChange={(e) => adSetFormik.setFieldValue('lifetime_budget', e.target.value)}
                                    onBlur={adSetFormik.handleBlur}
                                    borderRight="0"
                                    borderLeftRadius="none"
                                    size={'sm'}
                                    onWheel={(e) => e.target.blur()} 
                                />
                                <InputRightAddon height={'32px'} fontSize={'14px'}>
                                        {activeAccountsData?.data[0]?.currency}
                                </InputRightAddon>
                            </InputGroup>
                        )}
                    </Flex>
                    {adSetFormik.touched.budget && adSetFormik.errors.budget && (
                        <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.budget}</Text>
                    )}
                    {adSetFormik.values.budget === 'daily_budget' && adSetFormik.touched.daily_budget && adSetFormik.errors.daily_budget && (
                        <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.daily_budget}</Text>
                    )}
                    {adSetFormik.values.budget === 'lifetime_budget' && adSetFormik.touched.lifetime_budget && adSetFormik.errors.lifetime_budget && (
                        <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.lifetime_budget}</Text>
                    )}

                    {/* Schedule */}
                    <Flex alignItems={'center'} mt={2}>
                        <Text fontWeight="bold">Schedule</Text>
                        <Tooltip
                            borderRadius={5}
                            label={
                                <Box fontSize="sm" p={'5px'}>
                                    <Text textAlign={'justify'}>
                                        You can choose to run your ads continuously starting today or only during a specific date range.
                                    </Text>
                                </Box>
                            }
                            fontSize="md"
                        >
                            <InfoOutlineIcon pointerEvents="all" ml={2} />
                        </Tooltip>
                    </Flex>

                    <Flex direction="column" gap={2}>
                        {/* Start Date */}
                        <Text fontWeight={'bold'}>Start date</Text>
                        <Flex alignItems="center">
                            <Box flex="1" border="1px solid" borderColor="gray.300" borderRadius="md" p={2} bg="white">
                                <Flex alignItems="center">
                                    <CalendarIcon mr={2} />
                                    <DatePicker
                                        selected={startDate}
                                        placeholderText={formatUnixTimestamp(currentAdsetData?.start_time)}
                                        onChange={(date) => {
                                            setStartDate(date)
                                        }}
                                        minDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        disabled
                                    />
                                </Flex>
                            </Box>
                            <Box flex="1" border="1px solid" borderColor="gray.300" borderRadius="md" p={2} bg="white" ml={2}>
                                <Flex alignItems="center">
                                    <TimeIcon mr={2} />
                                    <Text>{moment(startDate).format("HH:mm")}</Text>
                                    <Text ml={2}>IST</Text>
                                </Flex>
                            </Box>
                        </Flex>

                        {/* End Date */}
                        {(adSetFormik.values.budget === 'daily_budget' || adSetFormik.values.budget === 'lifetime_budget') && (
                            <Flex direction="column" gap={2} mt={4}>
                                {adSetFormik.values.budget === 'daily_budget' && (
                                    <Checkbox
                                        colorScheme='orange'
                                        isChecked={showEndDate}
                                        onChange={() => setShowEndDate(!showEndDate)}
                                    >
                                        Set an end date
                                    </Checkbox>
                                )}
                                {(adSetFormik.values.budget === 'lifetime_budget' || showEndDate) && (
                                    <>
                                        <Text fontWeight={'bold'}>End date</Text>
                                        <Flex alignItems="center">
                                            <Box flex="1" border="1px solid" borderColor="gray.300" borderRadius="md" p={2} bg="white">
                                                <Flex alignItems="center">
                                                    <CalendarIcon mr={2} />
                                                    <DatePicker
                                                        selected={endDate}
                                                        onChange={(date) => setEndDate(date)}
                                                        minDate={new Date()}
                                                        placeholderText="dd/mm/yyyy"
                                                        dateFormat="dd/MM/yyyy"
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                    />
                                                </Flex>
                                            </Box>
                                            <Box flex="1" border="1px solid" borderColor="gray.300" borderRadius="md" p={2} bg="white" ml={2}>
                                                <Flex alignItems="center">
                                                    <TimeIcon mr={2} />
                                                    <Text>{moment(endDate).format("HH:mm")}</Text>
                                                    <Text ml={2}>IST</Text>
                                                </Flex>
                                            </Box>
                                        </Flex>
                                        <Flex>
                                            {errorDateMessage && <Text fontSize={'12px'} color="red.500">{errorDateMessage}</Text>}
                                        </Flex>
                                    </>
                                )}
                            </Flex>
                        )}
                    </Flex>
                </Flex>

                {/* Audience controls  */}
                <Flex bg="gray.50" p={4} flexDirection="column" borderRadius="md" gap={4} mt={4} overflow="visible" zIndex={1000} >
                    <Flex alignItems={'center'}>
                        <Text fontWeight="bold">Audience controls</Text>
                        <Tooltip
                            borderRadius={5}
                            label={
                                <Box fontSize="sm" p={'5px'}>
                                    <Text textAlign={'justify'}>
                                        <strong>Setting audience controls </strong><br></br>
                                        With Advantage+ audience, our system searches within these criteria for a more specific audience. To give our system flexibility to get results, we recommend setting these controls to create a broad audience.
                                    </Text>
                                </Box>
                            }
                            fontSize="md"
                        >
                            <InfoOutlineIcon pointerEvents="all" ml={2} />
                        </Tooltip>
                    </Flex>
                    {/* Targeting */}
                    <Flex flexDirection="column">
                        <Flex alignItems={'center'}>
                            <Text fontWeight="bold">{targeting.geo_locations.label}</Text>
                            <Text display="inline-flex" fontSize="18px" color="red.400">*</Text>
                            <Tooltip
                                borderRadius={5}
                                label={
                                    <Box fontSize="sm" p={'5px'}>
                                        <Text textAlign={'justify'}>
                                            Choosing a broad area to show your ads within can improve results. For example, by adding a country instead of several cities.
                                        </Text>
                                    </Box>
                                }
                                fontSize="md"
                            >
                                <InfoOutlineIcon pointerEvents="all" ml={2} />
                            </Tooltip>
                        </Flex>
                        <Text fontSize={'14px'}>Reach people living in or recently in this location.</Text>
                        <GeoLocationsSelector
                            preFilledExcludedLocations={currentAdsetData?.targeting?.excluded_geo_locations}
                            preFilledIncludedLocations={currentAdsetData?.targeting?.geo_locations}
                            onLocationsChange={handleLocationsChange}
                            onBlur={adSetFormik.handleBlur}
                        />
                        {adSetFormik.touched.geo_locations && adSetFormik.errors.geo_locations && (
                            <Text fontSize={'sm'} color="red.500">{adSetFormik.errors.geo_locations}</Text>
                        )}
                    </Flex>
                    <Divider />

                    {/* Age Gender & Detailed targeting */}
                    <Box>
                        <Box>
                            <Flex alignItems="center" justifyContent="space-between">
                                <Flex flexDirection={'column'} gap={2}>
                                    <Text fontWeight={'bold'}>Audience</Text>
                                    <Button onClick={() => setIsExpanded(!isExpanded)} variant="link" rightIcon={isExpanded ? <ChevronUpIcon color={'gray.800'} /> : <ChevronDownIcon color={'gray.800'} />}>
                                        <Text fontSize={'14px'} color={'gray.800'}>{isExpanded ? 'Hide Options' : 'Show More Options'}</Text>
                                    </Button>
                                </Flex>
                            </Flex>
                        </Box>

                        {/* Collapsible content */}
                        <Collapse in={isExpanded}>
                            <Flex direction="column" gap={4} pt={4}>
                                <Flex direction="column">
                                    <Text fontWeight="bold">Detailed targeting</Text>
                                    <DetailedTargetingSelector preFilledTargets={flattenTargeting(currentAdsetData?.targeting)} adsAccounts={adsAccounts ? adsAccounts : campaignData?.adsAccount} onInterestsChange={handleInterestsChange} />
                                </Flex>
                                <AgeGenderSelector
                                    preFilledData={currentAdsetData?.targeting}
                                    onChange={handleAgeGenderChange} />
                                <Box>
                                    <Flex align={'center'} mt={'10px'}>
                                        <Text fontWeight="bold">Languages</Text>
                                        <Box>
                                            <Tooltip
                                                borderRadius={5}
                                                label={
                                                    <Box fontSize="sm" p={'5px'}>
                                                        <Text textAlign={'justify'}>
                                                            Enter a language to show your ads to people who use a language that isn't common to your location. Otherwise, leave this blank.
                                                        </Text>
                                                    </Box>
                                                }
                                                fontSize="md"
                                            >
                                                <InfoOutlineIcon pointerEvents="all" ml={2} />
                                            </Tooltip>
                                        </Box>
                                    </Flex>
                                    <LocaleSelector
                                        preFilledLocales={currentAdsetData?.targeting?.locales}
                                        onLocalesChange={(locales) => adSetFormik.setFieldValue('locales', locales)}
                                    />
                                </Box>
                            </Flex>

                        </Collapse>
                    </Box>
                </Flex>

                {/* Placements Section */}
                <Flex bg="gray.50" flexDirection="column" borderRadius="md" mt={4}>
                    <PlacementsSelector
                        currentAdsetData={currentAdsetData?.targeting}
                        optimization_goal={adSetFormik.values.optimization_goal}
                        objective={objective}
                        buying_type={buying_type}
                        billing_event={adSetFormik.values.billing_event}
                        destination_type={adSetFormik.values.destination_type}
                        onPlacementsChange={setPlacements}
                        account_id={firstAdAccountId}
                    />
                </Flex>

                {/* Submit Button */}
                <Flex justify="flex-start" my={'20px'} >
                    <Button
                        colorScheme="orange"
                        isLoading={adSetFormik.isSubmitting}    
                        onClick={handleSave}
                        size={'sm'} >Save</Button>
                </Flex>
            </form>
        </Box>
    );
}

export default LeadUpdateForm;
