import {
    Box,
    Flex,
    FormControl,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Switch,
    Text,
    Tooltip,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import AppTableWithPagination from 'components/AppTableWithPagination';
import CarouselAdUpdateForm from 'components/genericForms/CarouselAdUpdateForm';
import SingleAdUpdateForm from 'components/genericForms/SingleAdUpdateForm';
import { useEffect, useMemo, useState } from 'react';
import { FiEdit2 } from "react-icons/fi";
import { useGetAdCreativeQuery, useGetAdsListQuery, useUpdateAdStatusMutation } from 'store/campaigns/adsApi';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

function AdsTable({ adsAccountData }) {
    const toast = useToast();
    const [searchParams] = useSearchParams();
    const adId = searchParams.get('adId');
    const isSearchDisabled = Boolean(adId);
    const adName = searchParams.get('name');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [selectedAd, setSelectedAd] = useState(null);
    const [switchStates, setSwitchStates] = useState({});

    const { data: adsList, isFetching, isLoading, refetch } = useGetAdsListQuery({
        currentPage,
        searchValue,
        adsAccountId: adsAccountData?.adsAccountId
    }, { skip: !adsAccountData?.adsAccountId });

    const { data: adCreative, isFetching: isFetchingCreative, isLoading: isLoadingCreative, refetch: refetchCreative } = useGetAdCreativeQuery(
        { creativeId: selectedAd?.creative?.id },
        { skip: !selectedAd?.creative?.id || !selectedAd }
    );

    const [updateAdStatus, { isLoading: isAdStatusUpdating }] = useUpdateAdStatusMutation({});

    //console.log({adCreative})

    useEffect(() => {
        if (adId) {
            setSearchValue('');
        }
    }, [adId]);

    const filteredAds = useMemo(() => {
       
        if (adId) {
            return adsList?.data?.filter(ad => ad.ad_id === adId) || [];
        }
        return adsList?.data || []; 
    }, [adsList, adId]);

    useEffect(() => {
        if (adsAccountData?.adsAccountId) {
            refetch();
        }
    }, [adsAccountData?.adsAccountId]);

    useEffect(() => {
        if (adsList?.data) {
            const initialSwitchStates = {};
            adsList.data.forEach(ad => {
                initialSwitchStates[ad.ad_id] = ad.status;
            });
            setSwitchStates(initialSwitchStates);
        }
    }, [adsList]);

    useEffect(() => {
        if (selectedAd) {
            refetchCreative();
        }
    }, [selectedAd]);

    const handleSwitchChange = (adId, currentState) => {
        const newStatus = currentState === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';
        // update the switch state
        setSwitchStates(prev => ({ ...prev, [adId]: newStatus }));
        updateAdStatus({
            adId,
            payload: { status: newStatus }
        }).unwrap()
            .then(() => {
                refetch();
                toast({
                    position: "top-right",
                    title: `Ad ${newStatus === 'ACTIVE' ? 'activated' : 'paused'}`,
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                });
            })
            .catch((error) => {
                toast({
                    position: "top-right",
                    title: `Error updating Ad status`,
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                });
                console.error('Error updating Ad status:', error);

                // Revert switch state if API call fails
                setSwitchStates(prev => ({ ...prev, [adId]: currentState }));
            });
    };

    const columns = useMemo(
        () => [
            {
                Header: "Off/On",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    const isActive = switchStates[original.ad_id] ?? (original.status === 'ACTIVE');
                    const tooltipText = isActive ? 'Ad is ON' : 'Ad is OFF';

                    return (
                        <Flex gap={'10px'} align={'center'}>
                            <Tooltip label={tooltipText} fontSize='xs' >
                                <FormControl width={'fit-content'}>
                                    <Switch
                                        colorScheme='orange'
                                        size={'sm'}
                                        isChecked={original.status === 'ACTIVE'}
                                        onChange={() => handleSwitchChange(original.ad_id, original.status)}
                                    />
                                </FormControl>
                            </Tooltip>
                        </Flex>
                    )
                },
            },
            {
                Header: "Name",
                accessor: "name",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {
                    return (
                        <Flex gap={'10px'} align={'center'}>
                           {original.imageUrl ? (
                                <img
                                    src={original.imageUrl}
                                    alt={`${original.name} thumbnail`}
                                    style={{ width: '45px', minWidth: '45px', height: '35px', objectFit: 'cover' }}
                                />
                            ) : (
                                <img
                                src={original.imageUrl}
                                alt={' '}
                                style={{ width: '45px', minWidth: '45px', height: '35px', objectFit: 'cover' }}
                            />
                            )}
                            <Tooltip label='Edit' fontSize='xs' >
                                <Flex>
                                    <Icon
                                        _hover={{ color: "gray.500" }}
                                        as={FiEdit2}
                                        cursor={"pointer"}
                                        onClick={() => {
                                            setSelectedAd(original);
                                            onOpen();
                                        }}
                                    />
                                </Flex>
                            </Tooltip>
                            <Text>{original.name}</Text>
                        </Flex>
                    )            
                },
            },
            {
                Header: "Ad ID",
                accessor: "ad_id",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {

                    return (
                        <Flex gap={'10px'} align={'center'}>
                            <Text>{original.ad_id}</Text>
                        </Flex>
                    )
                },
            },
            {
                Header: "Creative ID",
                accessor: "creative id",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text>{original.creative.id}</Text>
                ),
            },
            {
                Header: "CPA",
                accessor: "cpa",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Text>{original.cpa || 'N/A'}</Text>
                ),
            },
            {
                Header: "Insight",
                accessor: "",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                    <Box>
                        {original.insight && Object.keys(original.insight).length > 0 ? (
                            <>
                                <Text fontSize={'12px'}>CTR: {original.insight?.ctr}</Text>
                                <Text fontSize={'12px'}>Spend: {original.insight?.spend}</Text>
                                <Text fontSize={'12px'}>Date Start: {moment(original.insight?.date_start).format("DD-MM-YYYY")}</Text>
                                <Text fontSize={'12px'}>Date Stop: {moment(original.insight?.date_stop).format("DD-MM-YYYY")}</Text>
                            </>
                        ) : (
                            null
                        )}
                    </Box>
                )   
            },
            {
                Header: "Status",
                accessor: "status",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => {

                    return (
                        <Text>{original.status}</Text>
                    )

                },
            },
            {
                Header: "Created Date",
                accessor: "createdDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                  <Text>{moment(original.createdDate).format("DD-MM-YYYY hh:mm A")}</Text>
        
                ),
            },
            {
                Header: "Updated Date",
                accessor: "updatedDate",
                Filter: "",
                filter: "",
                Cell: ({ row: { original } }) => (
                  <Text>{moment(original.updatedDate).format("DD-MM-YYYY hh:mm A")}</Text>
        
                ),
            },
        ],
        []
    );

    return (
        <>
            {/* EDIT AD MODAL */}
            <Modal
                size={"2xl"}
                isCentered={true}
                isOpen={isOpen}
                scrollBehavior={"inside"}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Ad</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    {isLoadingCreative ? (
                        <Spinner />
                    ) : adCreative?.object_story_spec?.link_data ? (
                        adCreative.object_story_spec.link_data.child_attachments ? (
                            <CarouselAdUpdateForm adData={selectedAd} adsAccountData={adsAccountData} onClose={onClose} />
                        ) : (
                            <SingleAdUpdateForm adData={selectedAd} adsAccountData={adsAccountData} onClose={onClose} />
                        )
                    ) : (
                        <Text>No data available for the selected ad.</Text>
                    )}
                </ModalBody>
                </ModalContent>
            </Modal>
            {isLoading ? (
                <Spinner mt="20px" />
                ) : (
                <AppTableWithPagination
                    columns={columns}
                    data={filteredAds || []}
                    searchEnabled={!isSearchDisabled}
                    isLoading={false}
                    isFetching={isFetching}
                    initialState={{ pageIndex: currentPage - 1 }}
                    paginationMeta={adsList?.meta || {}}
                    onPageChange={setCurrentPage}
                    onSearchInputChange={setSearchValue}
                    searchTerm={searchValue}
                />
                )}
        </>
    );
}

export default AdsTable;
