import { useEffect, useState, useMemo } from "react";
import {
  Flex,Text,Box,Select,Button
} from '@chakra-ui/react';
import { AddIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons';
import SidebarWithHeader from 'components/SidebarWithHeader';
import { useGetActiveAccountsListQuery } from 'store/adAccounts/adAccountsApi';
import AppTableWithPagination from "components/AppTableWithPagination";
import CreateRuleForm from "components/adRules/CreateRuleForm";


function AdRules() {
  const [searchValue, setsearchValue] = useState('');
  const [selectedAdsAccountId, setSelectedAdsAccountId] = useState('');
  const [selectedAccountData, setSelectedAccountData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: activeAccountsData } = useGetActiveAccountsListQuery();

  useEffect(() => {
    if (searchValue != '') {
      setCurrentPage(1);
    }
  }, [searchValue])

  useEffect(() => {
    if (activeAccountsData?.data?.length > 0) {
        setSelectedAdsAccountId(activeAccountsData.data[0].adsAccountId);
        setSelectedAccountData(activeAccountsData.data[0]);
    }
  }, [activeAccountsData]);

  useEffect(() => {
      if (activeAccountsData?.data?.length > 0) {
          const account = activeAccountsData.data.find(acc => acc.adsAccountId === selectedAdsAccountId);
          setSelectedAccountData(account);
      }
  }, [selectedAdsAccountId, activeAccountsData]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => (
          <>

              <Text>{original.name}</Text>

          </>
        ),
      },
      {
        Header: "Ad Account ID",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          return (
            <Box>
              {original?.adsAccounts.map((account, index) => (
                <Text key={index} fontSize={'12px'}>{account.adsAccountId}</Text>
              ))}
            </Box>
          )
        }
      },
      {
        Header: "Ad Account Name",
        accessor: "",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          return (
            <Box>
              {original?.adsAccounts.map((account, index) => (
                <Text key={index} fontSize={'12px'}>{account.adsAccountName}</Text>
              ))}
            </Box>
          )
        }
      },
      {
        Header: "Objective",
        accessor: "objective",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          let objective = '';

          if (original.objective == 'OUTCOME_SALES') {
            objective = 'Sales'
          } else if (original.objective == 'OUTCOME_LEADS') {
            objective = 'Leads'
          }

          return (
            <Text>
              {objective}
            </Text>
          )
        }
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: "",
        filter: "",
        Cell: ({ row: { original } }) => {
          return (
            // <Text>{original?.tree ? 'PUBLISHED': 'DRAFT'}</Text>
            <Text>{original?.status}</Text>
          )
        },
      },
    ],
    []
  );

  return (
    <SidebarWithHeader>
    <>
    <Flex direction={'column'} gap={4}>

      <Flex gap={'10px'} flex={1} align={'center'} mb={4}>
        <Text fontWeight={'bold'}>Ad accounts</Text>
        <Box flex={0.4}>
            <Select
                value={selectedAdsAccountId}
                onChange={(e) => setSelectedAdsAccountId(e.target.value)}
                size={'sm'}
                borderRadius={'md'}
            >
                <option value="">All Ad Accounts</option>
                {activeAccountsData?.data?.map(account => (
                    <option key={account._id} value={account.adsAccountId}>
                        {`${account.adsAccountName} (${account.adsAccountId})`}
                    </option>
                ))}
            </Select>
        </Box>
      </Flex>

      <Flex>
          <Button colorScheme="orange" onClick={openModal}>
            <AddIcon boxSize={3} />
            &nbsp;Create Rule
          </Button>
      </Flex>

      {/* <AppTableWithPagination
          columns={columns}
          // data={adspressoCampaigns?.data || []}
          // paginationMeta={adspressoCampaigns?.meta || {}}
          onPageChange={(index) => setCurrentPage(index)}
          onSearchInputChange={(searchInput) => setsearchValue(searchInput)}
          // searchEnabled={true}
          // isFetching={isFetching}
        // initialState={initialTableState}
        /> */}
    </Flex>


      <CreateRuleForm isOpen={isModalOpen} onClose={closeModal} />


    </>
    </SidebarWithHeader>
  )
}

export default AdRules