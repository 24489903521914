import { ArrowDownIcon, ArrowUpIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Skeleton,
    Spinner,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from 'react';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import { useExpanded, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import './appTable.css';

function AppTableWithPagination({ columns, data = [], searchEnabled, isLoading, isFetching, initialState, paginationMeta = {}, onPageChange, onSearchInputChange, searchTerm }) {


    const backgroundColor = useColorModeValue('gray.50', 'gray.700');
    const borderColor = useColorModeValue('gray.100', 'gray.700');
    const [showError, setShowError] = useState(false);


    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        onPageChange(currentPage + 1)
    }, [currentPage]);

    const { pageSize: pageSize_, totalItems, totalPages, hasNextPage, hasPrevPage } = paginationMeta;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            initialState: {
                pageIndex: currentPage ?? 0,
                pageSize: pageSize_ ?? 5,
                pageCount: totalPages,
                canPreviousPage: hasPrevPage,
                canNextPage: hasNextPage,
                ...initialState
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
    );


    /* SEARCH */

    const inputRef = useRef();
    const [searchValue, setsearchValue] = useState(searchTerm)
    const [hasSearched, setHasSearched] = useState(false);


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchValue?.length > 2) {
                onSearchInputChange(searchValue);
                setHasSearched(true);
            }
        }, 400);

        return () => clearTimeout(delayDebounceFn);
    }, [searchValue]);

    useEffect(() => {

        if (data?.length == 0) {
            setShowError(true);
            //setsearchValue('');
            onSearchInputChange('');
        }

    }, [data])

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false)
            }, 3000);
        }
    }, [showError])


    //  console.log({ hasSearched, data });



    return (

        <Box >

            {/* {showError ? (
                <Flex flexDirection={'column'} gap={'10px'} justifyContent={'center'} alignItems={'center'}>
                    <Text mb={4} fontSize={'sm'} color='red.500'>No results found. Try a different search term.</Text>
                    {searchEnabled && searchValue ? (
                        <Button size={'xs'} onClick={() => { setsearchValue(''); onSearchInputChange(''); }}>Reset</Button>
                    ) : null}
                </Flex>
            ) : null} */}

            {(!hasSearched && data?.length == 0) ? (<>
                No data available
            </>) : (<>
                <Box mb={'40px'}>
                    <>
                        {searchEnabled && (
                            <Box mb={'20px'}>
                                <InputGroup>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        children={<SearchIcon color='gray.300' />}
                                    />
                                    <Input
                                        ref={inputRef}
                                        type="text"
                                        value={searchValue || ""}
                                        onChange={e => {
                                            setsearchValue(inputRef.current.value);
                                        }}
                                        placeholder={`Search...`}
                                    />
                                    {searchValue &&
                                        <InputRightElement
                                            cursor={'pointer'}
                                            children={isFetching ? <Spinner /> : <CloseIcon fontSize={14} _hover={{ color: 'gray.600' }} color='gray.300' />}
                                            onClick={() => { setsearchValue(''); }} />}
                                </InputGroup>
                                {hasSearched && showError ? (
                                    <Flex flexDirection={'column'} gap={'10px'} justifyContent={'center'} alignItems={'center'}>
                                        <Text mt={4} fontSize={'sm'} color='red.500'>No results found. Try a different search term.</Text>
                                    </Flex>
                                ) : null}

                            </Box>
                        )}

                        <TableContainer >
                            {isLoading && (
                                <Skeleton height='5px' />
                            )}
                            <Table
                                className={'sticky'}
                                variant={'simple'}
                                size='md'
                                {...getTableProps()}
                                borderWidth={1}
                                borderColor={borderColor}

                            >
                                <Thead bg={backgroundColor} >
                                    {headerGroups.map(headerGroup => (
                                        <Tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    <Flex align={'center'} gap={'10px'}>
                                                        <Box as='span'> {column.render("Header")} </Box>
                                                        {column.isSorted &&
                                                            (
                                                                <Box as="span">
                                                                    {column.isSortedDesc
                                                                        ? (<ArrowDownIcon boxSize={3} ml={2} />)
                                                                        : (<ArrowUpIcon boxSize={3} ml={2} />)
                                                                    }
                                                                </Box>
                                                            )
                                                        }
                                                        <Box ml={2} as='span'>{column?.canFilter ? column.render('Filter') : null}</Box>
                                                    </Flex>
                                                </Th>
                                            ))}
                                        </Tr>
                                    ))}
                                </Thead>
                                <Tbody {...getTableBodyProps()}>
                                    {page.map((row, i) => {
                                        prepareRow(row);
                                        // //console.log(row);
                                        return (
                                            <React.Fragment key={i + 'row'}>
                                                <Tr  {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <Td {...cell.getCellProps()}
                                                                fontSize={'sm'}
                                                            >
                                                                {cell.render("Cell")}
                                                            </Td>
                                                        );
                                                    })}
                                                </Tr>
                                            </React.Fragment>
                                        );
                                    })}
                                </Tbody>
                            </Table>


                        </TableContainer>
                        {/* <Box mt={4}>Showing the first 20 results of {rows.length} rows</Box> */}
                        {totalPages > 1 && (
                            <Flex align={'center'} justify={['start', 'end']} mt={'40px'} gap={'5px'}>
                                <Box mr={2} fontSize='sm'>
                                    Page{' '}
                                    <Text fontWeight={'bold'} display={'inline-flex'}>
                                        {currentPage + 1} of {totalPages}
                                    </Text>{' '}
                                </Box>

                                <Button size={'xs'} onClick={() => {
                                    setCurrentPage(0);
                                }} isDisabled={!hasPrevPage}>
                                    <Icon boxSize={4} as={FiChevronsLeft} />
                                </Button>{' '}

                                <Button size={'xs'} onClick={() => {
                                    if (currentPage + 1 > 1) {
                                        setCurrentPage(currentPage - 1);
                                    }
                                }} isDisabled={!hasPrevPage} >
                                    <Icon boxSize={4} as={FiChevronLeft} />
                                </Button>{' '}

                                <Button size={'xs'} onClick={() => {
                                    if (currentPage + 1 < totalPages) {
                                        setCurrentPage(currentPage + 1);
                                    }
                                }} isDisabled={!hasNextPage}>
                                    <Icon boxSize={4} as={FiChevronRight} />
                                </Button>{' '}

                                <Button size={'xs'} onClick={() => {
                                    setCurrentPage(totalPages - 1)
                                }} isDisabled={!hasNextPage}>
                                    <Icon boxSize={4} as={FiChevronsRight} />
                                </Button>{' '}

                            </Flex>
                        )}
                    </>
                </Box>

            </>)}


        </Box>
    )
}

export default AppTableWithPagination